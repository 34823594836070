<template>
  <div>
    <div v-if="model===false">
      <div class="totalmakeHole">
        <div class="makeHole1">
          <div class="screen1">
            <headtip @showDialog="showPartner"></headtip>
            <!--      logo  you气通GPT社区 合作伙伴  登录-->

            <!--       地质工程云平台GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
            <div class="layer2">
              <div class="title1">油气井增产技术服务
              </div>
              <div class="title2">
                致力于非常规油气开采关键技术研究及工业化应用，研发形成以地质研究及储层评价、非常规油油气藏压裂、增产措施效果监测及评估、老井增产技术为核心的四大技术系列，以全尺度缝控压裂、实时迭代压裂等为代表的20余项特色技术。
              </div>
              <div class="titlle3" @click="clickConsult('地质工程云平台')">
                产品咨询
              </div>
            </div>
            <!--      平台大图-->
            <div class="layer3"></div>
          </div>

          <div class="screenmake0">
            <div class="heighLight">技术体系组成</div>
            <div class="heightLight2" style="height: auto;margin-top: 4vh;">
              <img alt="" src="@/assets/subordinate/increasing1.png" style="height: 54vh;object-fit: fill">
            </div>
          </div>

          <div ref="consultMove" class="screenGpts">
            <div style="display: flex;margin:0 auto;">
              <div style="display: flex;margin:0 auto 0 auto;text-align: center;">
                <!--              <div style="height:2.2vw;text-align: right"><img loading="lazy" src="@/assets/icon_first.png">-->
                <!--              </div>-->
                <div class="title">&nbsp;主要技术
                </div>
              </div>
            </div>
            <div class="pro3">

              <!--油田-->
              <div class="grand2">
                <div class="back ">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw;width: 60%">
                    <img loading="lazy" src="@/assets/subordinate/increasing2.png"
                         style="border-top-left-radius: 9px;border-top-right-radius: 9px;height: 40vh;"/>
                  </div>

                  <div class="titleDesign">
                    <!-- <div class="title1">主要技术</div> -->
                    <div class="title2">
                      <li>以地质工程一体化优化设计平台，以缝内压力分析和裂缝形态模拟为核心的实时迭代压裂技术</li>
                      <li>以多功能暂堵剂为特色的复合暂堵压裂技术体系</li>
                      <li>多功能一体化变粘压裂液体系、多功能复合支撑剂技术体系</li>
                      <li>广域电磁法裂缝监测技术、长效固体示踪剂产剖测试技术</li>
                      <li>声电复合、液流空化纯物理储层改造技术</li>
                      <li>新型调剖调驱、稠油改质化学品技术</li>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div ref="consultMove" class="screenGpts text-box" style="background-color: #fff;">
            <div style="display: flex;margin:0 auto;">
              <div style="display: flex;margin:0 auto 0 auto;text-align: center;">
                <!--              <div style="height:2.2vw;text-align: right"><img loading="lazy" src="@/assets/icon_first.png">-->
                <!--              </div>-->
                <div class="title">&nbsp;主要技术
                </div>
              </div>
            </div>
            <div class="pro3">

              <!--油田-->
              <div class="grand2">
                <div class="back ">

                  <div class="titleDesign">
                    <div class="title1">①地质工程一体化团队：</div>
                    <div class="title2">
                      <li>
                        专业增产技术专家团队：15年以上储层改造领域经验，可为致密砂岩、页岩、碳酸盐岩等各类型油气藏提供定制化解决方案；
                      </li>
                      <li>
                        行业领先的一体化软件平台：可开展地质与裂缝建模、压裂酸化优化设计模拟、管柱力学分析、产能预测、压后评估分析等工作；
                      </li>
                      <li>
                        依托公司油藏地质-钻井-完井-增产-油田管理全产业链：可提供地质工程一体化项目研究及方案设计、现场实施
                      </li>
                    </div>
                  </div>
                </div>
              </div>

              <div class="grand2">
                <div class="back ">

                  <div class="titleDesign">
                    <div class="title1">②前沿技术追踪及交流：</div>
                    <div class="title2">
                      <li>国际前沿技术追踪：设有专门的休斯顿研发中心，聚焦非常规油气藏，定期反馈新技术、新方向、新发展</li>
                      <li>
                        国际技术交流与合作平台：定期与国内外知名油服开展技术合作研讨、交流；国际论坛、国际技术合作伙伴沟通交流
                      </li>
                    </div>
                  </div>
                </div>
              </div>

              <div class="grand2">
                <div class="back ">

                  <div class="titleDesign">
                    <div class="title1">③研发创新能力：</div>
                    <div class="title2">
                      <li>
                        始终坚持自主创新，大力推进精准地质工程技术建设，授权博士后科研工作站，曾先后被授予北京市高精尖设计中心、北京市企业技术中心等称号；
                      </li>
                      <li>
                        获北京市科学技术进步奖；授权专利40余项，研发的页岩油气滑溜水、暂堵技术、实时迭代压裂技术达到行业先进水平；
                      </li>
                    </div>
                  </div>
                </div>
              </div>


              <div class="grand2">
                <div class="back ">

                  <div class="titleDesign">
                    <div class="title1">④全系列、定制化配套技术产品：</div>
                    <div class="title2">
                      <li>
                        行业内主流的液体体系及单剂资源，依托公司化学技术及产品生产产业链，可提供定制化工作液设计及技术服务；
                      </li>
                      <li>拥有自主研发、生产基地，可提供全体系、定制化增产管柱设计；</li>
                      <li>配套齐全的措施效果监测评估技术，国际先进、主流监测评价公司战略合作方；</li>
                    </div>
                  </div>
                </div>
              </div>


              <div class="grand2">
                <div class="back ">

                  <div class="titleDesign">
                    <div class="title1">⑤国内外丰富的非常规油气藏项目实施经验</div>
                    <div class="title2">
                      <li>
                        自2005年起累计为中石油、中石化、中海油、壳牌等全球20余家客户提供增产技术服务，累计服务井次超过2000井次，涵盖页岩油气、致密砂岩、碳酸盐岩、煤层气、火成岩等各类常规及非常规油气藏，并创造了业内多项纪录；
                      </li>
                      <li>2018年开始承担地质工程一体化研究及风险增产技术总包服务，通过地质工程紧密结合，为客户提供一体化整体解决方案
                        ，提产提效显著，在国内多个油田区块实现产量突破。
                      </li>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>

          <bottomTip></bottomTip>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
    <div v-else>
      <div class="totalmakeHoleModel">
        <div class="makeHole1">
          <div class="screen1">
            <div style="margin:0 15px;">
              <headtip @showDialog="showPartner"></headtip>
            </div>

            <!--      logo  you气通GPT社区 合作伙伴  登录-->

            <!--       地质工程云平台GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
            <div class="layer2">
              <div class="title1">油气井增产技术服务
              </div>
              <div class="title2">
                致力于非常规油气开采关键技术研究及工业化应用，研发形成以地质研究及储层评价、非常规油油气藏压裂、增产措施效果监测及评估、老井增产技术为核心的四大技术系列，以全尺度缝控压裂、实时迭代压裂等为代表的20余项特色技术。
              </div>
            </div>
            <div class="titlle3" @click="clickConsult('地质工程云平台')">
              产品咨询
            </div>
            <!--      平台大图-->
            <div class="layer3"></div>
          </div>

          <div class="screenmake0">
            <div class="heighLight">技术体系组成</div>
            <div class="heightLight2" style="height: auto;margin-top: 15px;">
              <img alt="" src="@/assets/subordinate/increasing1.png" >
            </div>
          </div>

          <div ref="consultMove" class="screenmake0" style="background-color: transparent">
            <div style="display: flex;margin:0 auto;">
              <div style="display: flex;margin:0 auto 0 auto;text-align: center;">
                <!--              <div style="height:2.2vw;text-align: right"><img loading="lazy" src="@/assets/icon_first.png">-->
                <!--              </div>-->
                <div class="heighLight">&nbsp;主要技术
                </div>
              </div>
            </div>
            <div class="grid">

              <!--油田-->
              <div class="grand2">
                <div class="back ">
                  <div class="photo" style="background-color: white;">
                    <img loading="lazy" src="@/assets/subordinate/increasing2.png"
                         style="border-top-left-radius: 9px;border-top-right-radius: 9px;"/>
                  </div>

                  <div class="titleDesign">
                    <!-- <div class="title1">主要技术</div> -->
                    <div class="title2">
                      <li>以地质工程一体化优化设计平台，以缝内压力分析和裂缝形态模拟为核心的实时迭代压裂技术</li>
                      <li>以多功能暂堵剂为特色的复合暂堵压裂技术体系</li>
                      <li>多功能一体化变粘压裂液体系、多功能复合支撑剂技术体系</li>
                      <li>广域电磁法裂缝监测技术、长效固体示踪剂产剖测试技术</li>
                      <li>声电复合、液流空化纯物理储层改造技术</li>
                      <li>新型调剖调驱、稠油改质化学品技术</li>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div ref="consultMove" class="screenmake0" style="background-color: #fff;">
            <div style="display: flex;margin:0 auto;">
              <div style="display: flex;margin:0 auto 0 auto;text-align: center;">
                <!--              <div style="height:2.2vw;text-align: right"><img loading="lazy" src="@/assets/icon_first.png">-->
                <!--              </div>-->
                <div class="heighLight">&nbsp;主要技术
                </div>
              </div>
            </div>
            <div class="grid">

              <!--油田-->
              <div class="grand2">
                <div class="back ">

                  <div class="titleDesign">
                    <div class="title1">①地质工程一体化团队：</div>
                    <div class="title2">
                      <li>
                        专业增产技术专家团队：15年以上储层改造领域经验，可为致密砂岩、页岩、碳酸盐岩等各类型油气藏提供定制化解决方案；
                      </li>
                      <li>
                        行业领先的一体化软件平台：可开展地质与裂缝建模、压裂酸化优化设计模拟、管柱力学分析、产能预测、压后评估分析等工作；
                      </li>
                      <li>
                        依托公司油藏地质-钻井-完井-增产-油田管理全产业链：可提供地质工程一体化项目研究及方案设计、现场实施
                      </li>
                    </div>
                  </div>
                </div>
              </div>

              <div class="grand2">
                <div class="back ">

                  <div class="titleDesign">
                    <div class="title1">②前沿技术追踪及交流：</div>
                    <div class="title2">
                      <li>国际前沿技术追踪：设有专门的休斯顿研发中心，聚焦非常规油气藏，定期反馈新技术、新方向、新发展</li>
                      <li>
                        国际技术交流与合作平台：定期与国内外知名油服开展技术合作研讨、交流；国际论坛、国际技术合作伙伴沟通交流
                      </li>
                    </div>
                  </div>
                </div>
              </div>

              <div class="grand2">
                <div class="back ">

                  <div class="titleDesign">
                    <div class="title1">③研发创新能力：</div>
                    <div class="title2">
                      <li>
                        始终坚持自主创新，大力推进精准地质工程技术建设，授权博士后科研工作站，曾先后被授予北京市高精尖设计中心、北京市企业技术中心等称号；
                      </li>
                      <li>
                        获北京市科学技术进步奖；授权专利40余项，研发的页岩油气滑溜水、暂堵技术、实时迭代压裂技术达到行业先进水平；
                      </li>
                    </div>
                  </div>
                </div>
              </div>


              <div class="grand2">
                <div class="back ">

                  <div class="titleDesign">
                    <div class="title1">④全系列、定制化配套技术产品：</div>
                    <div class="title2">
                      <li>
                        行业内主流的液体体系及单剂资源，依托公司化学技术及产品生产产业链，可提供定制化工作液设计及技术服务；
                      </li>
                      <li>拥有自主研发、生产基地，可提供全体系、定制化增产管柱设计；</li>
                      <li>配套齐全的措施效果监测评估技术，国际先进、主流监测评价公司战略合作方；</li>
                    </div>
                  </div>
                </div>
              </div>


              <div class="grand2">
                <div class="back ">

                  <div class="titleDesign">
                    <div class="title1">⑤国内外丰富的非常规油气藏项目实施经验</div>
                    <div class="title2">
                      <li>
                        自2005年起累计为中石油、中石化、中海油、壳牌等全球20余家客户提供增产技术服务，累计服务井次超过2000井次，涵盖页岩油气、致密砂岩、碳酸盐岩、煤层气、火成岩等各类常规及非常规油气藏，并创造了业内多项纪录；
                      </li>
                      <li>2018年开始承担地质工程一体化研究及风险增产技术总包服务，通过地质工程紧密结合，为客户提供一体化整体解决方案
                        ，提产提效显著，在国内多个油田区块实现产量突破。
                      </li>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>
          <div ref="bottom">
            <bottomTip></bottomTip>
          </div>

        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
  </div>


</template>


<script>

import headtip from "@/views/headTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import consultTip from "@/components/consultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import {shareUrl} from "@/utils/vxshare";

export default {

  name: "",

  props: [],

  components: {PartnerTip, consultTip, headtip, bottomTip},

  data() {
    return {
      title: '',
      pageTitle: '油气井增产技术服务-油气增产技术GPTs-地质工程云平台GPT',
      pageDescription: '致力于非常规油气开采关键技术研究及工业化应用，研发形成以地质研究及储层评价、非常规油油气藏压裂、增产措施效果监测及评估、老井增产技术为核心的四大技术系列，以全尺度缝控压裂、实时迭代压裂等为代表的20余项特色技术。',
      pageKeywords: '油气井增产技术服务,油气增产技术GPTs,地质工程云平台GPT',
      model: false,
      dialogconsult: false,
      dialogVisible: false,
      success: [{
        img: require("@/assets/tube2.png"),

        title3: "" +
            "<li>2007年第一口井现场试验成功，截止2021年4月底，已经在胜利、中原、华北、普光气田、南阳、江苏、克拉玛依、大港、青海等10个油田整形916口井，成功763口井，成功率83.3%；近三年现场施工成功率达95.1%。</li>"
      },
      ]
    }

  },


  methods: {
    clickConsult(item) {

      this.title = item
      this.dialogVisible = true;
      sessionStorage.setItem('title', item)
    },
    hide() {
      this.dialogconsult = false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    realtime() {
      window.open("/realtime")
    },
    showPartner() {
      this.dialogconsult = true;
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    }, setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
  },

  mounted() {
    document.title = this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);
    if (screenWidth <= 700) {
      this.model = true
    } else {
      this.model = false
    }

    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
  },
  created() {
    localStorage.setItem("home", 0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: this.pageTitle, //'油气井增产技术服务-油气增产技术GPTs-地质工程云平台GPT',
        pageDescription: this.pageDescription, //'致力于非常规油气开采关键技术研究及工业化应用，研发形成以地质研究及储层评价、非常规油油气藏压裂、增产措施效果监测及评估、老井增产技术为核心的四大技术系列，以全尺度缝控压裂、实时迭代压裂等为代表的20余项特色技术。',
        pageKeywords: this.pageKeywords, // '油气井增产技术服务,油气增产技术GPTs,地质工程云平台GPT',

      };
      shareUrl(shareData);
    }, 1000);
  },
}

</script>


<style lang="scss" scoped type="text/scss">
.totalmakeHole {
  background: #c0d8f9;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;

  .makeHole1 {
    ////z-index::: 3;
    position: relative;
    width: 100%;
    height: 100vh;

    .screen1 {
      ////z-index::: 3;
      position: relative;
      width: 100%;
      height: 74vh;

      padding: 2.84301606922126vh 1.5vw 2.84301606922126vh 5vw;
      background-image: url("../../../assets/subordinate/special3.jpg");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */

      & > div {
        position: relative;
        z-index: 10;
      }

      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, #becff5 30%, rgba(198, 211, 239, 0.5));
      }

      .layer2 {
        position: absolute;
        top: 50%;
        transform: translateY(-43%);
        left: 6.5vw;

        .title1 {
          width: fit-content;
          height: fit-content;
          font-size: 2.5vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #2f6eff;
          line-height: 3vh;
        }

        .title2 {
          margin-top: 5vh;
          width: 40vw;
          height: fit-content;
          text-align: justify;
          font-size: 1.0597920277vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: black;
          line-height: 4.31520395550062vh;
        }

        .titlle3 {
          margin-top: 5vh;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #ffffff;
          line-height: 3.3374536465vh;
          text-align: center;
          border-radius: 53px;
          width: fit-content;
          padding: 2.4624227441vh 1.1989601386vw;
          height: 4.5624227441vh;
          background: #2f6eff;
          border: #2f6eff 1px solid;

        }

        .titlle3:hover {

          color: #2168DB;
          border: #2168DB 1px solid;
          background: rgba(255, 255, 255, 0.5);
        }
      }


      //.layer3 {
      //  position: absolute;
      //  top: 5.06798516687268vh;
      //  right: 11.61178509532062vw;
      //  width: 47.42923165800116vw;
      //  height: 72.80593325092707vh;
      //  background-image: url("../assets/GPTplant.png");
      //  background-size: 100% auto;
      //  background-position: center;
      //  background-repeat: no-repeat;
      //  background-color: transparent;
      //  opacity: 1;
      //}
    }

    .screenGpts {
      z-index: 10;
      width: 100vw;
      height: fit-content;
      border-radius: 9px;
      padding: 4.78739184177998vh 8.09763142692085vw;
      background: transparent;

      .title {

        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 1.5vw;
        //color: #356ff8;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 4.23733003708282vh;
      }

      .light {
        margin: 0 auto 4vh auto;
        text-align: justify;
        width: 66vw;
        height: fit-content;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        //color: #3172dd;
        line-height: 4.94437577255871vh;

      }


      .pro3 {
        //margin-top: -4vh;
        height: fit-content;
        width: 100%;
        z-index: 89156;
        row-gap: 2vh;

        .grand2 {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.39324090121317vw;
          opacity: 1;

          .back {
            transition: transform 0.5s ease;
            width: 100%;
            position: relative;
            height: 100%;
            background: white;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            border: 2px solid #FFFFFF;
            border-radius: 9px;
            display: flex;
          }

          .photo {
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-color: transparent; /* 这里设置背景颜色为透明 */
            opacity: 1.25;
            margin: 0 auto auto auto;
            display: flex;
            justify-content: center;
            text-align: center;
            width: fit-content;
            height: 100%;
          }


          .title1 {
            padding: 0;
            opacity: 4;
            text-align: left;
            font-size: 1vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: bold;
            //color: #2168DB;
            line-height: 5vh;
          }

          .title2 {
            opacity: 4;
            text-align: justify;
            justify-content: center;
            align-items: center;
            font-size: 0.9vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            //color: #2168DB;
            line-height: 2.71940667490729vh;
          }

          .titleDesign {
            margin: auto;
            padding: 0 3vw;
            width: 50%;
            float: right;
            text-align: justify;
          }

          .consult {
            position: absolute;
            display: none;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            font-size: 0.9vw;
            bottom: 3.94437577255871vh;
            left: 1.85540150202195vw;
          }
        }

        .grand2:hover {
          .back {
            transform: translateY(-10px);

          }

        }
      }

      &.text-box {
        .pro3 {
          display: grid;
          grid-template-columns: repeat(5, 1fr);

          .grand2 {
            .back {
              min-height: 51vh;

              .titleDesign {
                margin: unset;
                width: unset;
                padding: 2vh 3vh 3vh;

                .title1 {
                  line-height: 1.4;
                  margin-bottom: 2vh;
                  text-align: center;
                }
              }
            }
          }
        }
      }


    }

    .screenmake4 {
      padding: 5vh 3.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: #212a3c;

      .heighLight {
        display: flex;
        justify-content: center;
        text-align: center;
        height: 5.23733003708282vh;
        font-size: 1.5vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: bold;
        color: white;
        line-height: 4.23733003708282vh;

      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;

        .card {
          //cursor: pointer;
          width: 86%;
          margin: 0 auto;
          height: fit-content;
          padding: 0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;

          .back {
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height: 68vh;
            overflow: hidden;
            background: #19202e;

            .imgDesign {
              background-color: white;
              width: fit-content;
              height: 100%;
              margin: 0 auto;
              opacity: 1;
            }

            .titleDesign {
              margin: auto;
              color: white;
              width: 35%;
              float: right;
              text-align: left;

              .title1 {
                line-height: 4vh;
                font-size: 1.1709416522vw;
              }

              .title2 {
                line-height: 3.5vh;
                font-size: 0.9vw;

                ::v-deep li {
                  margin-top: 5px;
                }
              ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 70vh !important;

        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          outline: 10vh;
          padding: 0;
          margin: 0;
          //cursor: pointer;

          border-radius: 50%;
          width: 55px;
          height: 55px;
          background-color: rgba(249, 250, 252, 0.2);
          border: rgba(255, 255, 255, 0.5) 1px solid;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 100;
          transform: translateY(-50%);
          text-align: center;
          font-weight: 800;
          font-size: 25px;
        }
      }
    }

    .screenmake0 {
      padding: 5vh 15.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height: 6.23733003708282vh;
        opacity: 1;
        font-weight: bold;
        font-size: 1.5vw;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin: 0 auto 20px;
        // text-align: center;
        height: 8.78739184177998vh;
        // font-weight: bold;
        color: #2C3E50;
        line-height: 4.94437577255871vh;
        // width: 60.9693818602vw;
        font-size: 1vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            background: #EFF5FF;
            border-radius: 9px;
            display: flex;
            position: relative;
            box-shadow: 2px 0 12px 0px #c9d9f5;
            height: 66.87144622991347vh;
            overflow: hidden;

            .imgDesign {
              width: fit-content;
              height: 100%;
              opacity: 1;
              background-image: url("../../../assets/menage6.png");
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 4.5248454883vh 2.5248454883vw 2.5248454883vh 1.5248454883vw;
              margin: 0 1vw auto auto;
              color: black;
              width: 40%;
              float: right;

              .title1 {
                margin-top: 2vh;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }

              .title2 {
                margin-top: 1vh;
                text-align: justify;
                line-height: 3.194066749vh;
                font-size: 0.9vw;;
              }

              .title3 {
                display: grid;
                grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.9vw;;
              }

            }

          }
        }


      }
    }
  }

}

.totalmakeHoleModel {
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;

  .makeHole1 {
    ////z-index::: 3;
    position: relative;
    width: 100%;
    height: 100vh;

    .screen1 {
      ////z-index::: 3;
      position: relative;
      width: 100%;
      height: 400px;
      padding: 2.84301606922126vh 0  2.84301606922126vh 0;
      background-image: url("../../../assets/integratedstimulationM.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */

      .layer2 {
        position: absolute;
        top: 75px;
        width: 100%;

        .title1 {
          width:fit-content;
          text-align: center;
          display: flex;
          margin:0 auto;
          justify-content: center;
          align-items: center;
          font-size: 22px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: white;
        }

        .title2 {
          margin: 15px auto;
          width: 90%;
          height: fit-content;
          font-size: 14px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: white;
          line-height: 24px;
        }
        .title4{
          margin-top: 15px;

          height: 22px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          font-size:16px;
          color: #2f6dfd;
          line-height: 0px;
          font-style: normal;
          text-transform: none;
        }


      }
      .titlle3{
        position: absolute;
        bottom:30px;
        left:50%;
        transform: translateX(-60%);
        font-size: 14px;
        font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 53px;
        width: fit-content;
        margin: 0 15px;
        padding: 5px 4.6875vw;
        height: 35px;
        color:white;
        background-color: #2f6eff;
        border: 1px solid #2f6eff;
        overflow: hidden;

      }

    }

    .screenmake0{
      ////z-index::: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 20px;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;

      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid{
        position: relative;
        height: fit-content;
        margin-top:25px;

        .title1 {
          opacity: 4;
          margin:15px 0 0 15px;
          text-align: left;

          height: fit-content;
          font-size: 16px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: bold;
          color: #2168DB;
          line-height: 22px;
        }

        .title2 {
          opacity: 4;
          margin: 10px 15px;
          text-align: justify;
          font-size:14px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }
        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          ////z-index::: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }

    .screenmake4 {
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;

      padding: 20px 4.6875vw;
      background: black;

      .heighLight {
        text-align: center;
        height: 21px;
        opacity: 1;
        font-size: 21px;
        color: white;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }

      .heightLight2 {
        margin: 19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size: 14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;

        .arrowButtonleft {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          left: -55px;
          ////z-index::: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }

        .arrowButtonright {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          right: -55px;
          ////z-index::: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }

        .card {
          //cursor: pointer;
          width: 98%;
          margin: 0 auto;
          min-height: 250px;
          padding: 0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;

          .back {
            border-radius: 9px;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            min-height: 280px;
            overflow: hidden;
            background: #19202e;

            .imgDesign {
              width: 100%;
              height: 214px;
              opacity: 1;
              background-image: url("../../../assets/menage6.png");
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 15px 20px 25px 20px;
              color: white;
              width: 100%;
              float: right;

              .title1 {
                margin-top: 15px;
                font-size: 16px;
              }

              .title2 {
                margin-top: 15px;
                line-height: 22px;
                font-size: 14px;

                ::v-deep li {
                  margin-top: 5px;
                }
              ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 340px !important;

        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__arrow {
          display: block;
          //border: 1px solid white;
          outline: -5px;
          padding: 0;
          margin: -68px -10px;
          //cursor: pointer;

          border-radius: 0;
          width: 25px;
          height: 48px;
          background: rgba(245, 246, 250);
          color: #131313;
          position: absolute;
          top: 50%;
          ////z-index::: 100;
          transform: translateY(-50%);
          text-align: center;
          font-size: 14px;
        }
      }
    }
  }

}
</style>
