import { render, staticRenderFns } from "./conventional.vue?vue&type=template&id=524fda86&scoped=true"
import script from "./conventional.vue?vue&type=script&lang=js"
export * from "./conventional.vue?vue&type=script&lang=js"
import style0 from "./conventional.vue?vue&type=style&index=0&id=524fda86&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "524fda86",
  null
  
)

export default component.exports