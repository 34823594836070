import { render, staticRenderFns } from "./tube.vue?vue&type=template&id=5e58a2c3&scoped=true"
import script from "./tube.vue?vue&type=script&lang=js"
export * from "./tube.vue?vue&type=script&lang=js"
import style0 from "./tube.vue?vue&type=style&index=0&id=5e58a2c3&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e58a2c3",
  null
  
)

export default component.exports