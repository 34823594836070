
<template>
  <div>
    <div v-if="model===false">
      <div class="totalmakeHole">
        <div class="makeHole1">
          <div class="screen1">
            <headtip @showDialog="showPartner"></headtip>
            <!--      logo  you气通GPT社区 合作伙伴  登录-->

            <!--       地质工程云平台GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
            <div class="layer2">
              <div class="title1">非常规油气藏全尺度缝控压裂技术服务
              </div>
              <div class="title2">全尺度缝控压裂技术应用在非常规油气藏压裂改造（页岩、砂岩、深层煤层气等）中，是由构建多尺度裂缝和全尺度裂缝充填两个设计理念构成，通过提升改造后的波及体积和裂缝有效导流能力，提高压后初期产量并延长稳产期，最终实现最优化的累计产量。</div>   <div class="titlle3" @click="clickConsult('地质工程云平台')">
                产品咨询
              </div>
            </div>
            <!--      平台大图-->
            <div class="layer3"></div>
          </div>

          <div class="screenmake0">
                <div class="heighLight">技术思路
                </div>
            <div class="heightLight2">以提升压裂改造后的波及体积和裂缝有效导流能力，提出全尺度缝控压裂技术，通过构建多尺度裂缝、全尺度裂缝充分实现裂缝三维全支撑，最终实现最优化的累计产量。
            </div>
            <div style="width: 100%;height: fit-content;text-align: center;">
              <img style="border-radius: 9px;width: 80%;" loading="lazy" src="@/assets/subordinate/fracture1.png"/>

            </div>
          </div>
          <div class="screenmake0" style="background-color: transparent">
            <div class="heighLight">核心技术
            </div>
            <div class="lr-box">
              <div style="height: fit-content">
                <img style="border-radius: 9px;width: 100%;" loading="lazy" src="@/assets/subordinate/fracture2.png"/>
              </div>
              <div>
                <h4>① 精细地质研究 + 地质工程一体化压裂设计：</h4>
                <p>通过建立属性、天然裂缝、地应力三维精细综合模型，为精细化设计提供依据。</p>
                <p>地质与工程相结合，通过精细三维地质及地质力学建模，准确认识砂体展布开展个性化、精细化布缝设计；储层渗流能力与裂缝导流能力匹配，精确设计施工规模及参数，实现“一段一策”的精细化压裂设计。</p>
              </div>
            </div>
          </div>

          <div class="screenmake0" >
            <div class="lr-box rl">
              <div>
                <h4>② 聚合物干粉变粘压裂液+纳米防水锁功能液技术体系：</h4>
                <p>通过常规聚合物干粉改性，使其溶解性能和抗盐性能极大提高，同时研制出配套的高效连续混配设备，实现施工现场的压裂液即供、即配、即注，达到提高降本、增效、环保效果。</p>
                <p>采用纳米表面活性剂，利用材料的独特空间位阻效应，减少吸附的同时有效改变岩石润湿性，增大接触角，作用于地层，实现助排、防水锁功效，达到增产目的。</p>
              </div>
              <div style="height: fit-content">
                <img style="border-radius: 9px;width: 100%;" loading="lazy" src="@/assets/subordinate/fracture3.png"/>
              </div>
            </div>
          </div>

          <div class="screenmake0" style="background-color: transparent">
            <div class="lr-box">
              <div style="height: fit-content">
                <img style="border-radius: 9px;width: 100%;height: 40vh;" loading="lazy" src="@/assets/subordinate/fracture4.png"/>
              </div>
              <div>
                <h4>③ 多级粒径支撑剂+自悬浮支撑剂+固结砂防返吐技术体系：</h4>
                <p>优选多级粒径支撑剂组合，适时支撑及多级粒径支撑剂匹配设计采取不同粒径比例、加入时机及加入方式。</p>
                <p>自悬浮支撑剂进行水溶性涂覆层后，分散到液体中后具有自悬浮功能，能够在不增粘、不增加排量的情况下改善铺砂剖面，降低施工压力，提高裂缝的充填效果，实现全尺度裂缝支撑，最终提高单井产量。</p>
                <p>柔性固结砂在一定条件下具有自粘结典型特征，且透油、透气、透水的一种压裂支撑剂产品，通过尾追一部分柔性固结砂，可以达到防止地层出砂、防止支撑剂回流的目的。</p>
              </div>
            </div>
          </div>

          <div class="screenmake0">
            <div class="lr-box rl">
              <div>
                <h4>④实时迭代压裂技术+可视化监测技术：</h4>
                <p>地质工程一体化实时迭代分析（压裂曲线实时诊断、地质模型更新迭代等），实现裂缝扩展实时评估及地质再认识。</p>
                <p>结合广域电磁监测开展方案优化及调整：通过监测压裂液入地后产生的电性变化引起的电磁响应，获取电磁时间域差分异常，反映压裂液波及范围，进而分析缝网特征。</p>
              </div>
              <div style="height: fit-content">
                <img style="border-radius: 9px;width: 100%;" loading="lazy" src="@/assets/subordinate/fracture5.png"/>
              </div>
            </div>
          </div>

          <div class="screenmake0" style="background-color: transparent">
            <div class="lr-box">
              <div style="height: fit-content">
                <img style="border-radius: 9px;width: 100%;height: 40vh;object-fit: contain;background-color: #fff;" loading="lazy" src="@/assets/subordinate/fracture6.png"/>
              </div>
              <div>
                <h4>⑤可溶性绳结暂堵转向技术：</h4>
                <p>可溶绳结暂堵塞通过异形可溶性暂堵塞封堵炮眼或近井裂缝缝口，可更有效实现段内裂缝分流转向，提高不同物性、地应力层/簇均衡改造程度，达到裂缝均衡扩展、进一步提产的效果。</p>
              </div>
            </div>
          </div>

          <bottomTip></bottomTip>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
    <div v-else>
      <div class="totalmakeHoleModel">
        <div class="makeHole1">
          <div class="screen1">
            <div style="margin:0 15px;">
              <headtip @showDialog="showPartner"></headtip>
            </div>

            <!--      logo  you气通GPT社区 合作伙伴  登录-->

            <!--       地质工程云平台GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
            <div class="layer2">
              <div class="title1">非常规油气藏全尺度缝控压裂技术服务
              </div>
              <div class="title2">全尺度缝控压裂技术应用在非常规油气藏压裂改造（页岩、砂岩、深层煤层气等）中，是由构建多尺度裂缝和全尺度裂缝充填两个设计理念构成，通过提升改造后的波及体积和裂缝有效导流能力，提高压后初期产量并延长稳产期，最终实现最优化的累计产量。</div>

            </div>
            <div class="titlle3" @click="clickConsult('地质工程云平台')">
              产品咨询
            </div>
            <!--      平台大图-->
            <div class="layer3"></div>
          </div>
          <div class="screenmake0">
            <div class="heighLight">技术思路
            </div>
            <div class="heightLight2">以提升压裂改造后的波及体积和裂缝有效导流能力，提出全尺度缝控压裂技术，通过构建多尺度裂缝、全尺度裂缝充分实现裂缝三维全支撑，最终实现最优化的累计产量。
            </div>
            <div style="width: 100%;height: fit-content;text-align: center;">
              <img style="border-radius: 9px;width: 80%;" loading="lazy" src="@/assets/subordinate/fracture1.png"/>

            </div>
          </div>
          <div class="screenmake0" style="background-color: transparent">
            <div class="heighLight">核心技术
            </div>
            <div class="grid">
              <div style="height: fit-content" >
                <img style="border-radius: 9px;width: 100%;" loading="lazy" src="@/assets/subordinate/fracture2.png"/>
              </div>
              <div style="margin-top: 10px">
                <div class="title1">① 精细地质研究 + 地质工程一体化压裂设计：</div>
                <div class="title2">通过建立属性、天然裂缝、地应力三维精细综合模型，为精细化设计提供依据。</div>
                <div class="title2">地质与工程相结合，通过精细三维地质及地质力学建模，准确认识砂体展布开展个性化、精细化布缝设计；储层渗流能力与裂缝导流能力匹配，精确设计施工规模及参数，实现“一段一策”的精细化压裂设计。</div>
              </div>
            </div>
          </div>

          <div class="screenmake0" >
            <div class="grid">
              <div style="height: fit-content">
                <img style="border-radius: 9px;width: 100%;" loading="lazy" src="@/assets/subordinate/fracture3.png"/>
              </div>
              <div>
                <div class="title1">② 聚合物干粉变粘压裂液+纳米防水锁功能液技术体系：</div>
                <div class="title2">通过常规聚合物干粉改性，使其溶解性能和抗盐性能极大提高，同时研制出配套的高效连续混配设备，实现施工现场的压裂液即供、即配、即注，达到提高降本、增效、环保效果。</div>
                <div class="title2">采用纳米表面活性剂，利用材料的独特空间位阻效应，减少吸附的同时有效改变岩石润湿性，增大接触角，作用于地层，实现助排、防水锁功效，达到增产目的。</div>
              </div>

            </div>
          </div>

          <div class="screenmake0" style="background-color: transparent">
            <div class="grid">
              <div style="height: fit-content">
                <img style="border-radius: 9px;width: 100%" loading="lazy" src="@/assets/subordinate/fracture4.png"/>
              </div>
              <div>
                <div class="title1">③ 多级粒径支撑剂+自悬浮支撑剂+固结砂防返吐技术体系：</div>
                <div class="title2">优选多级粒径支撑剂组合，适时支撑及多级粒径支撑剂匹配设计采取不同粒径比例、加入时机及加入方式。</div>
                <div class="title2">自悬浮支撑剂进行水溶性涂覆层后，分散到液体中后具有自悬浮功能，能够在不增粘、不增加排量的情况下改善铺砂剖面，降低施工压力，提高裂缝的充填效果，实现全尺度裂缝支撑，最终提高单井产量。</div>
                <div class="title2">柔性固结砂在一定条件下具有自粘结典型特征，且透油、透气、透水的一种压裂支撑剂产品，通过尾追一部分柔性固结砂，可以达到防止地层出砂、防止支撑剂回流的目的。</div>
              </div>
            </div>
          </div>

          <div class="screenmake0">
            <div class="grid">
              <div style="height: fit-content">
                <img style="border-radius: 9px;width: 100%;" loading="lazy" src="@/assets/subordinate/fracture5.png"/>
              </div>
              <div>
                <div class="title1">④实时迭代压裂技术+可视化监测技术：</div>
                <div class="title2">地质工程一体化实时迭代分析（压裂曲线实时诊断、地质模型更新迭代等），实现裂缝扩展实时评估及地质再认识。</div>
                <div class="title2">结合广域电磁监测开展方案优化及调整：通过监测压裂液入地后产生的电性变化引起的电磁响应，获取电磁时间域差分异常，反映压裂液波及范围，进而分析缝网特征。</div>
              </div>

            </div>
          </div>

          <div class="screenmake0" style="background-color: transparent">
            <div class="grid">
              <div style="height: fit-content">
                <img style="border-radius: 9px;width: 100%;;object-fit: contain;" loading="lazy" src="@/assets/subordinate/fracture6.png"/>
              </div>
              <div>
                <div class="title1">⑤可溶性绳结暂堵转向技术：</div>
                <div class="title2">可溶绳结暂堵塞通过异形可溶性暂堵塞封堵炮眼或近井裂缝缝口，可更有效实现段内裂缝分流转向，提高不同物性、地应力层/簇均衡改造程度，达到裂缝均衡扩展、进一步提产的效果。</div>
              </div>
            </div>
          </div>
          <div ref="bottom">
            <bottomTip></bottomTip>
          </div>

        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
  </div>



</template>


<script>

import headtip from "@/views/headTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import consultTip from "@/components/consultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import {shareUrl} from "@/utils/vxshare";
export default {

  name: "",

  props: [],

  components: {PartnerTip, consultTip, headtip,bottomTip},

  data() {
    return {
      title:'',
      pageTitle: '非常规油气藏全尺度缝控压裂技术服务-油气增产技术GPTs-地质工程云平台GPT',
      pageDescription: '全尺度缝控压裂技术应用在非常规油气藏压裂改造（页岩、砂岩、深层煤层气等）中，是由构建多尺度裂缝和全尺度裂缝充填两个设计理念构成，通过提升改造后的波及体积和裂缝有效导流能力，提高压后初期产量并延长稳产期，最终实现最优化的累计产量。',
      pageKeywords: '非常规油气藏全尺度缝控压裂技术服务,油气增产技术GPTs,地质工程云平台GPT',
      model:false,
      dialogconsult:false,
      dialogVisible:false,
      success:[{
        img:require("@/assets/tube2.png"),

        title3:"" +
            "<li>2007年第一口井现场试验成功，截止2021年4月底，已经在胜利、中原、华北、普光气田、南阳、江苏、克拉玛依、大港、青海等10个油田整形916口井，成功763口井，成功率83.3%；近三年现场施工成功率达95.1%。</li>"
      },
      ]
    }

  },


  methods: {
    clickConsult(item){

      this.title=item
      this.dialogVisible = true;
      sessionStorage.setItem('title',item)
    },
    hide(){
      this.dialogconsult=false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    realtime(){
      window.open("/realtime")
    },
    showPartner(){
      this.dialogconsult=true;
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },    setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
  },

  mounted() {
    document.title=this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);
    if(screenWidth<=700){
      this.model=true
    }else{
      this.model=false
    }

    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
  },
  created() {
    localStorage.setItem("home",0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: this.pageTitle, //'智能工程迭代优化GPTs-工程地质技术GPTs-地质工程云平台GPT',
        pageDescription: this.pageDescription, //'依托云的管理模式，工程服务与地质相结合，立足油气田已有成果，结合基础油藏地质研究，提供实时、动态的优化建议，以达到提升工程效果，降低工程风险的效果。',
        pageKeywords: this.pageKeywords, //'智能工程迭代优化GPTs,工程地质技术GPTs,地质工程云平台GPT',

      };
      shareUrl(shareData);
    }, 1000);
  },
}

</script>


<style type="text/scss" lang="scss" scoped>

.totalmakeHole{
  background: #c0d8f9;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .makeHole1 {
    ////z-index::: 3;
    position: relative;
    width: 100%;
    height: 100vh;
    .screen1 {
      ////z-index::: 3;
      position: relative;
      width: 100%;
      height: 74vh;

      padding: 2.84301606922126vh 1.5vw  2.84301606922126vh 5vw;
      background-image: url("../../../assets/subordinate/special1.png");
      background-position: right;
      background-size: fill;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */


      & > div{
        position: relative;
        z-index: 10;
      }
      &::after{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, #becff5 30%, rgba(198, 211, 239, 0.5));
      }

      .layer2 {
        position: absolute;
        top: 50%;
        transform: translateY(-43%);
        left: 6.5vw;

        .title1 {
          width: fit-content;
          height: fit-content;
          font-size: 2.5vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #2f6eff;
          line-height: 3vh;
        }

        .title2 {
          margin-top: 5vh;
          width: 40vw;
          height: fit-content;
          text-align: justify;
          font-size: 1.0597920277vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: black;
          line-height: 4.31520395550062vh;
        }
        .titlle3{
          margin-top: 5vh;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #ffffff;
          line-height: 3.3374536465vh;
          text-align: center;
          border-radius: 53px;
          width: fit-content;
          padding: 2.4624227441vh 1.1989601386vw;
          height: 4.5624227441vh;
          background: #2f6eff;
          border: #2f6eff 1px solid;

        }
        .titlle3:hover{

          color: #2168DB;
          border: #2168DB 1px solid;
          background: rgba(255, 255, 255, 0.5);
        }
      }


      //.layer3 {
      //  position: absolute;
      //  top: 5.06798516687268vh;
      //  right: 11.61178509532062vw;
      //  width: 47.42923165800116vw;
      //  height: 72.80593325092707vh;
      //  background-image: url("../assets/GPTplant.png");
      //  background-size: 100% auto;
      //  background-position: center;
      //  background-repeat: no-repeat;
      //  background-color: transparent;
      //  opacity: 1;
      //}
    }
    .screenGpts {
      z-index: 10;
      width: 100vw;
      height: fit-content;
      border-radius: 9px;
      padding: 4.78739184177998vh 8.09763142692085vw;
      background: transparent;

      .title {

        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 1.5vw;
        //color: #356ff8;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 4.23733003708282vh;
      }

      .light {
        margin: 0 auto 4vh auto;
        text-align: justify;
        width: 66vw;
        height: fit-content;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        //color: #3172dd;
        line-height: 4.94437577255871vh;

      }


      .pro3 {
        //margin-top: -4vh;
        height: fit-content;
        width: 100%;
        z-index: 89156;

        grid-template-columns:minmax(0, 1fr);
        row-gap: 2vh;
        .grand2 {
          //cursor: pointer;
          width: 100%;
          height:fit-content;
          padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.39324090121317vw;
          opacity: 1;

          .back {
            transition: transform 0.5s ease;
            width: 100%;
            position: relative;
            height: 100%;
            background: white;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            border: 2px solid #FFFFFF;
            border-radius: 9px;
            display: flex;
          }
          .photo {
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-color: transparent; /* 这里设置背景颜色为透明 */
            opacity: 1.25;
            margin: 0 auto auto auto;
            display: flex;
            justify-content: center;
            text-align: center;
            width: fit-content;
            height: 100%;
          }


          .title1 {
            padding: 0;
            opacity: 4;
            text-align: left;
            font-size: 1vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: bold;
            //color: #2168DB;
            line-height: 5vh;
          }

          .title2 {
            opacity: 4;
            text-align: justify;
            justify-content: center;
            align-items: center;
            font-size:0.9vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            //color: #2168DB;
            line-height: 2.71940667490729vh;
          }
          .titleDesign{
            margin:auto;
            padding: 0 3vw;
            width: 50%;
            float: right;
            text-align: justify;
          }

          .consult {
            position: absolute;
            display: none;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            font-size:0.9vw;
            bottom: 3.94437577255871vh;
            left: 1.85540150202195vw;
          }
        }

        .grand2:hover {
          .back {
            transform: translateY(-10px);

          }

        }
      }



    }

    .screenmake4{
      padding: 5vh 3.09763142692085vw;
      width: 100%;
      height:fit-content;
      background: #212a3c;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:5.23733003708282vh;
        font-size: 1.5vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: bold;
        color:white;
        line-height: 4.23733003708282vh;

      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .card{
          //cursor: pointer;
          width: 86%;
          margin:0 auto;
          height:fit-content ;
          padding:0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;
          .back{
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height:68vh ;
            overflow:hidden;
            background: #19202e;
            .imgDesign{
              background-color: white;
              width: fit-content;
              height:100%;
              margin:0 auto;
              opacity: 1;
            }
            .titleDesign{
              margin:auto;
              color: white;
              width: 35%;
              float: right;
              text-align: left;
              .title1{
                line-height: 4vh;
                font-size: 1.1709416522vw;
              }
              .title2{
                line-height: 3.5vh;
                font-size: 0.9vw;
                ::v-deep li{
                  margin-top:5px;
                }
              ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container{
          height:70vh !important;

        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          outline: 10vh;
          padding: 0;
          margin: 0  ;
          //cursor: pointer;

          border-radius: 50%;
          width: 55px;
          height: 55px;
          background-color: rgba(249, 250, 252, 0.2);
          border: rgba(255, 255, 255, 0.5) 1px solid;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 100;
          transform: translateY(-50%);
          text-align: center;
          font-weight:800;
          font-size: 25px;
        }
      }
    }

    .screenmake0{
      padding: 5vh 15.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height:6.23733003708282vh;
        opacity: 1;
        font-weight: bold;
        font-size: 1.5vw;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin:0 auto 20px;
        // text-align: center;
        height: 8.78739184177998vh;
        // font-weight: bold;
        color: #2C3E50;
        line-height: 4.94437577255871vh;
        width: 60.9693818602vw;
        font-size: 1vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back{
            background: #EFF5FF;
            border-radius: 9px;
            display: flex;
            position: relative;
            box-shadow: 2px 0 12px 0px #c9d9f5;
            height:66.87144622991347vh ;
            overflow:hidden;
            .imgDesign{
              width:fit-content;
              height:100%;
              opacity: 1;
              background-image: url("../../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 4.5248454883vh 2.5248454883vw 2.5248454883vh 1.5248454883vw;
              margin:0 1vw auto auto;
              color: black;
              width: 40%;
              float: right;
              .title1{
                margin-top: 2vh;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }
              .title2{
                margin-top: 1vh;
                text-align: justify;
                line-height: 3.194066749vh;
                font-size: 0.9vw;;
              }
              .title3{
                display: grid;
                grid-template-columns: minmax(0,1fr) minmax(0,1fr);
                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.9vw;;
              }

            }

          }
        }


      }
    }
  }

}

.totalmakeHoleModel{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .makeHole1 {
    ////z-index::: 3;
    position: relative;
    width: 100%;
    height: 100vh;
    .screen1 {
      ////z-index::: 3;
      position: relative;
      width: 100%;
      height: 400px;
      padding: 2.84301606922126vh 0  2.84301606922126vh 0;
      background-image: url("../../../assets/subordinate/special1.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */
      & > div {
        position: relative;
        z-index: 10;
      }

      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to bottom, #43a8fb 60%, rgba(198, 211, 239, 0.5));
      }
      .layer2 {
        position: absolute;
        top: 75px;
        width: 100%;

        .title1 {
          width:fit-content;
          text-align: center;
          display: flex;
          margin:0 auto;
          justify-content: center;
          align-items: center;
          font-size: 22px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: white;
        }

        .title2 {
          margin: 15px auto;
          width: 90%;
          height: fit-content;
          font-size: 14px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: white;
          line-height: 24px;
        }
        .title4{
          margin-top: 15px;

          height: 22px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          font-size:16px;
          color: #2f6dfd;
          line-height: 0px;
          font-style: normal;
          text-transform: none;
        }


      }
      .titlle3{
        position: absolute;
        bottom:30px;
        left:50%;
        transform: translateX(-60%);
        font-size: 14px;
        font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 53px;
        width: fit-content;
        margin: 0 15px;
        padding: 5px 4.6875vw;
        height: 35px;
        color:white;
        background-color: #2f6eff;
        border: 1px solid #2f6eff;
        overflow: hidden;

      }

    }
    .screenmake0{
      ////z-index::: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 20px;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;

      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid{
        position: relative;
        height: fit-content;
        margin-top:25px;

        .title1 {
          opacity: 4;
          margin:15px 0 0 15px;
          text-align: left;

          height: fit-content;
          font-size: 16px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: bold;
          color: #2168DB;
          line-height: 22px;
        }

        .title2 {
          opacity: 4;
          margin: 10px 15px;
          text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size:14px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }
        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          ////z-index::: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }

    .screenmake4{
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;

      padding:20px 4.6875vw;
      background: black;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        color: white;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .arrowButtonleft {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          left:-55px;
          ////z-index::: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }
        .arrowButtonright {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          right: -55px;
          ////z-index::: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }
        .card{
          //cursor: pointer;
          width: 98%;
          margin:0 auto;
          min-height: 250px ;
          padding:0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;
          .back{
            border-radius: 9px;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            min-height: 280px ;
            overflow:hidden;
            background: #19202e;
            .imgDesign{
              width: 100%;
              height:214px;
              opacity: 1;
              background-image: url("../../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 15px 20px 25px 20px;
              color: white;
              width:100%;
              float: right;
              .title1{
                margin-top:15px;
                font-size: 16px;
              }
              .title2{
                margin-top: 15px;
                line-height: 22px;
                font-size:14px;
                ::v-deep li{
                  margin-top:5px;
                }
              ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container{
          height: 340px !important;

        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__arrow {
          display: block;
          //border: 1px solid white;
          outline: -5px;
          padding: 0;
          margin: -68px -10px  ;
          //cursor: pointer;

          border-radius: 0;
          width: 25px;
          height: 48px;
          background: rgba(245, 246, 250);
          color: #131313;
          position: absolute;
          top: 50%;
          ////z-index::: 100;
          transform: translateY(-50%);
          text-align: center;
          font-size: 14px;
        }
      }
    }
  }

}

.lr-box{
  display: flex;
  align-items: center;
  margin-top: 3vh;
  h4{
    font-size: 1.2vw;
    margin-bottom: 3vh;
  }
  p{
    margin-bottom: .5vh;
    font-size: 1vw;
    line-height: 2;
  }
  & > div{
    flex: 1;
  }
  & > div:first-child{
    width: 50%;
    margin-right: 3vw;
  }
}
</style>
