
<template>
  <div>
    <div v-if="model===false">
      <div class="totalmakeHole">
        <div class="makeHole1">
          <div class="screen1">
            <headtip @showDialog="showPartner"></headtip>
            <!--      logo  you气通GPT社区 合作伙伴  登录-->

            <!--       地质工程云平台GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
            <div class="layer2">
              <div class="title1">增产设计及评价技术服务
              </div>
              <div class="title2">提供依托精细储层评价技术、大量物模实验数据，借助行业主流设计软件和自主开发软件，提供针对性的增产方案。同时可以提供以物模实验为手段的方案可行性分析及化学助剂性能测试，以示踪剂产液剖面数据、微地震数据、电磁法数据等为手段的增产措施效果评价及设计方案后评估。</div>   <div class="titlle3" @click="clickConsult('地质工程云平台')">
                产品咨询
              </div>
            </div>
            <!--      平台大图-->
            <div class="layer3"></div>
          </div>

          <div class="screenGpts1" style="background-color: white"   ref="consultMove">
            <div style="display: flex;margin:0 auto;">
              <div style="display: flex;margin:0 auto 0 auto;text-align: center;">
                <!--              <div style="height:2.2vw;text-align: right"><img loading="lazy" src="@/assets/icon_first.png">-->
                <!--              </div>-->
                <div class="title">服务内容
                </div>
              </div>
            </div>
            <div class="pro3">

              <!--油田-->
              <div class="grand2">
                <div class="back ">
                  <div class="photo" >
                    <img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/earthquake1.png"/>
                  </div>
                  <div class="titleDesign">
                    <div class="title1">提供各种油藏条件下的储层增产方案可行性分析研究报告 </div>

                  </div>
                </div>
                <div class="back ">
                  <div class="photo" >
                    <img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/earthquake2.png"/>
                  </div>
                  <div class="titleDesign">
                    <div class="title1">提供各种油藏条件下的储层增产方案及工程设计 </div>

                  </div>
                </div>
                <div class="back ">
                  <div class="photo" >
                    <img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/earthquake3.png"/>
                  </div>
                  <div class="titleDesign">
                    <div class="title1">提供增产方案的适应性后评估报告 </div>

                  </div>
                </div>

                <div class="back ">
                  <div class="photo" >
                    <img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/earthquake4.png"/>
                  </div>
                  <div class="titleDesign">
                    <div class="title1">提供化学助剂性能评价报告 </div>

                  </div>
                </div>

              </div>
            </div>

          </div>

          <div class="screenmake0" >
            <div class="heighLight">设计服务能力</div>
            <div class="lr-box">
              <div style="height: fit-content">
                <img style="border-radius: 9px;width: 100%;height: 40vh;" loading="lazy" src="@/assets/subordinate/evaluation1.png"/>
              </div>
              <div>
                <li>配套地质工程一体化软件平台及主流的压裂、酸化、油藏分析、管柱分析等设计软件，辅以自主开发的特色设计软件</li>
                <li>可开展常规及非常规油气藏井地质与裂缝建模、压裂酸化优化设计模拟、管柱力学分析、产能预测、压后评估分析等工作</li>
                <li>安东实时迭代压裂技术是地质工程一体化、智能压裂技术的集成应用，该技术结合软件模拟、硬件监测、针对性的分析方法，可以有效达到“压裂实时分析、过程精细控制、效果深化评价”目标，实现最大程度提高储层改造体积，实现效益最大化。</li>
              </div>
            </div>
          </div>

          <div class="screenGpts2"   ref="consultMove">
            <div style="display: flex;margin:0 auto;">
              <div style="display: flex;margin:0 auto 0 auto;text-align: center;">
                <!--              <div style="height:2.2vw;text-align: right"><img loading="lazy" src="@/assets/icon_first.png">-->
                <!--              </div>-->
                <div class="title">实验室服务能力
                </div>
              </div>
            </div>
            <div class="pro3">

              <!--油田-->
              <div class="grand2">
                <div class="back ">
                  <div class="photo" >
                    <img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/subordinate/evaluation12.png"/>
                  </div>
                  <div class="titleDesign">
                    <div class="title1">提供各种油藏条件下的储层增产方案可行性分析研究报告 </div>

                  </div>
                </div>
                <div class="back ">
                  <div class="photo" >
                    <img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/subordinate/evaluation11.png"/>
                  </div>
                  <div class="titleDesign">
                    <div class="title1">提供各种油藏条件下的储层增产方案及工程设计 </div>

                  </div>
                </div>
                <div class="back ">
                  <div class="photo" >
                    <img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/subordinate/evaluation13.png"/>
                  </div>
                  <div class="titleDesign">
                    <div class="title1">提供增产方案的适应性后评估报告 </div>

                  </div>
                </div>
              </div>
            </div>

          </div>


          <div class="screenmake4">
            <div class="heighLight">成功案例</div>
            <div class="grid">
              <el-carousel indicator-position="outside" arrow="always">
                <el-carousel-item v-for="item in success" :key="item">
                  <div class="card">
                    <div class="back">

                      <div style="background-color: white;width: 55%">
                        <div class="imgDesign">
                          <img loading="lazy" :src="item.img"/>
                        </div>
                      </div>

                      <div class="titleDesign">
                        <div class="title1" v-html="item.title2"></div>
                        <div class="title1" v-html="item.title1"></div>
                        <div class="title2" v-html="item.title3"></div>
                      </div>
                    </div>


                  </div>


                </el-carousel-item>
              </el-carousel>
            </div>

          </div>

          <bottomTip></bottomTip>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
    <div v-else>
      <div class="totalmakeHoleModel">
        <div class="makeHole1">
          <div class="screen1">
            <div style="margin:0 15px;">
              <headtip @showDialog="showPartner"></headtip>
            </div>

            <!--      logo  you气通GPT社区 合作伙伴  登录-->

            <!--       地质工程云平台GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
            <div class="layer2">
              <div class="title1">增产设计及评价技术服务
              </div>
              <div class="title2">提供依托精细储层评价技术、大量物模实验数据，借助行业主流设计软件和自主开发软件，提供针对性的增产方案。同时可以提供以物模实验为手段的方案可行性分析及化学助剂性能测试，以示踪剂产液剖面数据、微地震数据、电磁法数据等为手段的增产措施效果评价及设计方案后评估。</div>
            </div>
            <div class="titlle3" @click="clickConsult('地质工程云平台')">
              产品咨询
            </div>
            <!--      平台大图-->
            <div class="layer3"></div>
          </div>

          <div class="screenGpts1" style="background-color: white"   ref="consultMove">
            <div style="display: flex;margin:0 auto;">
              <div style="display: flex;margin:0 auto 0 auto;text-align: center;">
                <!--              <div style="height:2.2vw;text-align: right"><img loading="lazy" src="@/assets/icon_first.png">-->
                <!--              </div>-->
                <div class="title">服务内容
                </div>
              </div>
            </div>
            <div class="pro3">

              <!--油田-->
              <div class="grand2">
                <div class="back ">
                  <div class="photo" >
                    <img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/earthquake1.png"/>
                  </div>
                  <div class="titleDesign">
                    <div class="title1">提供各种油藏条件下的储层增产方案可行性分析研究报告 </div>

                  </div>
                </div>
                <div class="back ">
                  <div class="photo" >
                    <img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/earthquake2.png"/>
                  </div>
                  <div class="titleDesign">
                    <div class="title1">提供各种油藏条件下的储层增产方案及工程设计 </div>

                  </div>
                </div>
                <div class="back ">
                  <div class="photo" >
                    <img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/earthquake3.png"/>
                  </div>
                  <div class="titleDesign">
                    <div class="title1">提供增产方案的适应性后评估报告 </div>

                  </div>
                </div>

                <div class="back ">
                  <div class="photo" >
                    <img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/earthquake4.png"/>
                  </div>
                  <div class="titleDesign">
                    <div class="title1">提供化学助剂性能评价报告 </div>

                  </div>
                </div>

              </div>
            </div>

          </div>

          <div class="screenmake0" style="margin-top: -25px">
            <div class="heighLight">设计服务能力</div>
            <div class="grid">
              <div style="height: fit-content">
                <img style="border-radius: 9px;width: 100%;height: fit-content;" loading="lazy" src="@/assets/subordinate/evaluation1.png"/>
              </div>
              <div class="title2">
                <li>配套地质工程一体化软件平台及主流的压裂、酸化、油藏分析、管柱分析等设计软件，辅以自主开发的特色设计软件</li>
                <li>可开展常规及非常规油气藏井地质与裂缝建模、压裂酸化优化设计模拟、管柱力学分析、产能预测、压后评估分析等工作</li>
                <li>安东实时迭代压裂技术是地质工程一体化、智能压裂技术的集成应用，该技术结合软件模拟、硬件监测、针对性的分析方法，可以有效达到“压裂实时分析、过程精细控制、效果深化评价”目标，实现最大程度提高储层改造体积，实现效益最大化。</li>
              </div>
            </div>
          </div>

          <div class="screenGpts2"   ref="consultMove">
            <div style="display: flex;margin:0 auto;">
              <div style="display: flex;margin:0 auto 0 auto;text-align: center;">
                <!--              <div style="height:2.2vw;text-align: right"><img loading="lazy" src="@/assets/icon_first.png">-->
                <!--              </div>-->
                <div class="title">实验室服务能力
                </div>
              </div>
            </div>
            <div class="pro3">

              <!--油田-->
              <div class="grand2">
                <div class="back ">
                  <div class="photo" >
                    <img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/subordinate/evaluation12.png"/>
                  </div>
                  <div class="titleDesign">
                    <div class="title1">提供各种油藏条件下的储层增产方案可行性分析研究报告 </div>

                  </div>
                </div>
                <div class="back ">
                  <div class="photo" >
                    <img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/subordinate/evaluation11.png"/>
                  </div>
                  <div class="titleDesign">
                    <div class="title1">提供各种油藏条件下的储层增产方案及工程设计 </div>

                  </div>
                </div>
                <div class="back ">
                  <div class="photo" >
                    <img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/subordinate/evaluation13.png"/>
                  </div>
                  <div class="titleDesign">
                    <div class="title1">提供增产方案的适应性后评估报告 </div>

                  </div>
                </div>
              </div>
            </div>

          </div>


          <div class="screenmake4">
            <div class="heighLight">成功案例</div>
            <div class="grid">
              <el-carousel indicator-position="outside" arrow="always">
                <el-carousel-item v-for="item in success" :key="item">
                  <div class="card">
                    <div class="back">

                      <div>
                        <div class="imgDesign">
                          <img loading="lazy" :src="item.img"/>
                        </div>
                      </div>

                      <div class="titleDesign">
                        <div class="title1" v-html="item.title2"></div>
                        <div class="title1" v-html="item.title1"></div>
                        <div class="title2" v-html="item.title3"></div>
                      </div>
                    </div>


                  </div>


                </el-carousel-item>
              </el-carousel>
            </div>

          </div>
          <div ref="bottom">
            <bottomTip></bottomTip>
          </div>

        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
  </div>



</template>


<script>

import headtip from "@/views/headTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import consultTip from "@/components/consultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import {shareUrl} from "@/utils/vxshare";
export default {

  name: "",

  props: [],

  components: {PartnerTip, consultTip, headtip,bottomTip},

  data() {
    return {
      title:'',
      pageTitle: '增产设计及评价技术服务-油气增产技术GPTs-地质工程云平台GPT',
      pageDescription: '提供依托精细储层评价技术、大量物模实验数据，借助行业主流设计软件和自主开发软件，提供针对性的增产方案。同时可以提供以物模实验为手段的方案可行性分析及化学助剂性能测试，以示踪剂产液剖面数据、微地震数据、电磁法数据等为手段的增产措施效果评价及设计方案后评估。',
      pageKeywords: '增产设计及评价技术服务,油气增产技术GPTs,地质工程云平台GPT',
      model:false,
      dialogconsult:false,
      dialogVisible:false,
      success:[{
        img:require("@/assets/subordinate/evaluation7.png"),

        title3:"" +
            "<li>服务区域覆盖国内外多个区域，累计为超过1500井次提供设计及评价服务</li>"+
            "<li>国内最早推广体积压裂理念，承担中国第一口页岩气水平井增产方案并完成国内第一口页岩气超长水平井50段压裂技术总包服务</li>"+
            "<li>国内最早设计致密砂岩加砂单缝加砂量超过100m<sup>3</sup>的增产方案</li>"
      },
      ]
    }

  },


  methods: {
    clickConsult(item){

      this.title=item
      this.dialogVisible = true;
      sessionStorage.setItem('title',item)
    },
    hide(){
      this.dialogconsult=false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    realtime(){
      window.open("/realtime")
    },
    showPartner(){
      this.dialogconsult=true;
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },    setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
  },

  mounted() {
    document.title=this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);
    if(screenWidth<=700){
      this.model=true
    }else{
      this.model=false
    }

    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
  },
  created() {
    localStorage.setItem("home",0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: this.pageTitle, //'智能工程迭代优化GPTs-工程地质技术GPTs-地质工程云平台GPT',
        pageDescription: this.pageDescription, //'依托云的管理模式，工程服务与地质相结合，立足油气田已有成果，结合基础油藏地质研究，提供实时、动态的优化建议，以达到提升工程效果，降低工程风险的效果。',
        pageKeywords: this.pageKeywords, //'智能工程迭代优化GPTs,工程地质技术GPTs,地质工程云平台GPT',

      };
      shareUrl(shareData);
    }, 1000);
  },
}

</script>


<style type="text/scss" lang="scss" scoped>
img{
  object-fit: contain;
}
.totalmakeHole{
  background: #c0d8f9;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .makeHole1 {
    ////z-index::: 3;
    position: relative;
    width: 100%;
    height: 100vh;
    .screen1 {
      ////z-index::: 3;
      position: relative;
      width: 100%;
      height: 74vh;

      padding: 2.84301606922126vh 1.5vw  2.84301606922126vh 5vw;
      background-image: url("../../../assets/subordinate/special2.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */

      & > div{
        position: relative;
        z-index: 10;
      }
      &::after{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, #becff5 30%, rgba(198, 211, 239, 0.5));
      }

      .layer2 {
        position: absolute;
        top: 50%;
        transform: translateY(-43%);
        left: 6.5vw;

        .title1 {
          width: fit-content;
          height: fit-content;
          font-size: 2.5vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #2f6eff;
          line-height: 3vh;
        }

        .title2 {
          margin-top: 5vh;
          width: 40vw;
          height: fit-content;
          text-align: justify;
          font-size: 1.0597920277vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: black;
          line-height: 4.31520395550062vh;
        }
        .titlle3{
          margin-top: 5vh;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #ffffff;
          line-height: 3.3374536465vh;
          text-align: center;
          border-radius: 53px;
          width: fit-content;
          padding: 2.4624227441vh 1.1989601386vw;
          height: 4.5624227441vh;
          background: #2f6eff;
          border: #2f6eff 1px solid;

        }
        .titlle3:hover{

          color: #2168DB;
          border: #2168DB 1px solid;
          background: rgba(255, 255, 255, 0.5);
        }
      }


      //.layer3 {
      //  position: absolute;
      //  top: 5.06798516687268vh;
      //  right: 11.61178509532062vw;
      //  width: 47.42923165800116vw;
      //  height: 72.80593325092707vh;
      //  background-image: url("../assets/GPTplant.png");
      //  background-size: 100% auto;
      //  background-position: center;
      //  background-repeat: no-repeat;
      //  background-color: transparent;
      //  opacity: 1;
      //}
    }
    .screenGpts {
      z-index: 10;
      width: 100vw;
      height: fit-content;
      border-radius: 9px;
      padding: 4.78739184177998vh 8.09763142692085vw;
      background: transparent;

      .title {

        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 1.5vw;
        //color: #356ff8;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 4.23733003708282vh;
      }

      .light {
        margin: 0 auto 4vh auto;
        text-align: justify;
        width: 66vw;
        height: fit-content;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        //color: #3172dd;
        line-height: 4.94437577255871vh;

      }


      .pro3 {
        //margin-top: -4vh;
        height: fit-content;
        width: 100%;
        z-index: 89156;

        grid-template-columns:minmax(0, 1fr);
        row-gap: 2vh;
        .grand2 {
          //cursor: pointer;
          width: 100%;
          height:fit-content;
          padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.39324090121317vw;
          opacity: 1;

          .back {
            transition: transform 0.5s ease;
            width: 100%;
            position: relative;
            height: 100%;
            background: white;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            border: 2px solid #FFFFFF;
            border-radius: 9px;
            display: flex;
          }
          .photo {
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-color: transparent; /* 这里设置背景颜色为透明 */
            opacity: 1.25;
            margin: 0 auto auto auto;
            display: flex;
            justify-content: center;
            text-align: center;
            width: fit-content;
            height: 100%;
          }


          .title1 {
            padding: 0;
            opacity: 4;
            text-align: left;
            font-size: 1vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: bold;
            //color: #2168DB;
            line-height: 5vh;
          }

          .title2 {
            opacity: 4;
            text-align: justify;
            justify-content: center;
            align-items: center;
            font-size:0.9vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            //color: #2168DB;
            line-height: 2.71940667490729vh;
            & >li{
              margin: 10px 0;
            }
          }
          .titleDesign{
            margin:auto;
            padding: 0 3vw;
            width: 50%;
            float: right;
            text-align: justify;
          }

          .consult {
            position: absolute;
            display: none;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            font-size:0.9vw;
            bottom: 3.94437577255871vh;
            left: 1.85540150202195vw;
          }
        }

        .grand2:hover {
          .back {
            transform: translateY(-10px);

          }

        }
      }



    }

    .screenmake4{
      padding: 5vh 3.09763142692085vw;
      width: 100%;
      height:fit-content;
      background: #212a3c;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:5.23733003708282vh;
        font-size: 1.5vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: bold;
        color:white;
        line-height: 4.23733003708282vh;

      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .card{
          //cursor: pointer;
          width: 86%;
          margin:0 auto;
          height:fit-content ;
          padding:0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;
          .back{
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height:51vh ;
            overflow:hidden;
            background: #19202e;
            .imgDesign{
              background-color: white;
              width: fit-content;
              height:100%;
              margin:0 auto;
              opacity: 1;
            }
            .titleDesign{
              margin:auto;
              color: white;
              width: 35%;
              float: right;
              text-align: left;
              .title1{
                line-height: 4vh;
                font-size: 1.1709416522vw;
              }
              .title2{
                line-height: 3.5vh;
                font-size: 0.9vw;
                ::v-deep li{
                  margin-top:5px;
                }
              ;
              }
            }
          }
        }
        ::v-deep .el-carousel__container{
          height:53vh !important;

        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          outline: 10vh;
          padding: 0;
          margin: 0  ;
          //cursor: pointer;

          border-radius: 50%;
          width: 55px;
          height: 55px;
          background-color: rgba(249, 250, 252, 0.2);
          border: rgba(255, 255, 255, 0.5) 1px solid;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 100;
          transform: translateY(-50%);
          text-align: center;
          font-weight:800;
          font-size: 25px;
        }
      }
    }

    .screenmake0{
      padding: 5vh 15.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height:6.23733003708282vh;
        opacity: 1;
        font-weight: bold;
        font-size: 1.5vw;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin:0 auto 20px;
        // text-align: center;
        height: 8.78739184177998vh;
        // font-weight: bold;
        color: #2C3E50;
        line-height: 4.94437577255871vh;
        width: 60.9693818602vw;
        font-size: 1vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back{
            background: #EFF5FF;
            border-radius: 9px;
            display: flex;
            position: relative;
            box-shadow: 2px 0 12px 0px #c9d9f5;
            height:66.87144622991347vh ;
            overflow:hidden;
            .imgDesign{
              width:fit-content;
              height:100%;
              opacity: 1;
              background-image: url("../../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 4.5248454883vh 2.5248454883vw 2.5248454883vh 1.5248454883vw;
              margin:0 1vw auto auto;
              color: black;
              width: 40%;
              float: right;
              .title1{
                margin-top: 2vh;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }
              .title2{
                margin-top: 1vh;
                text-align: justify;
                line-height: 3.194066749vh;
                font-size: 0.9vw;;
              }
              .title3{
                display: grid;
                grid-template-columns: minmax(0,1fr) minmax(0,1fr);
                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.9vw;;
              }

            }

          }
        }


      }
    }

    .screenGpts1 {
      z-index: 10;
      width: 100vw;
      height: fit-content;
      border-radius: 9px;
      padding: 4.78739184177998vh 8.09763142692085vw;
      background: transparent;

      .title {

        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 1.5vw;
        //color: #356ff8;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 4.23733003708282vh;
      }

      .light {
        margin: 0 auto 4vh auto;
        text-align: justify;
        width: 66vw;
        height: fit-content;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        //color: #3172dd;
        line-height: 4.94437577255871vh;

      }


      .pro3 {
        //margin-top: -4vh;
        height: fit-content;
        width: 100%;
        z-index: 89156;

        grid-template-columns:minmax(0, 1fr);
        row-gap: 2vh;
        .grand2 {
          //cursor: pointer;
          width: 100%;
          height:fit-content;
          padding: 0.98887515451174vh 0 0.98887515451174vh 8vw;
          opacity: 1;
          display: grid;
          text-align: center;
          grid-template-columns:  repeat(2, 1fr);
          row-gap: 5vh;
          column-gap: 3vw;

          .back {
            transition: transform 0.5s ease;
            width: 100%;
            position: relative;
            height: 100%;
            background: white;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            border: 2px solid #FFFFFF;
            border-radius: 9px;
            display: flex;
          }
          .photo {
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-color: transparent; /* 这里设置背景颜色为透明 */
            opacity: 1.25;
            width: fit-content;
            height: 5vh;
          }


          .title1 {
            padding: 0;
            opacity: 4;
            text-align: left;
            font-size: 1vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: bold;
            //color: #2168DB;
            line-height: 5vh;
          }

          .title2 {
            opacity: 4;
            text-align: justify;
            justify-content: center;
            align-items: center;
            font-size:0.9vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            //color: #2168DB;
            line-height: 2.71940667490729vh;
          }
          .titleDesign{
            padding: 0;
            margin-left: 3vw;
            float: right;
            text-align: justify;
          }

          .consult {
            position: absolute;
            display: none;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            font-size:0.9vw;
            bottom: 3.94437577255871vh;
            left: 1.85540150202195vw;
          }
        }
        .grand {
          //cursor: pointer;
          width: 100%;
          height:fit-content;
          padding: 0.98887515451174vh 0;
          opacity: 1;
          display: grid;
          grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) ;
          row-gap: 5vh;
          column-gap: 1vw;

          .back {
            transition: transform 0.5s ease;
            width: 100%;
            position: relative;
            height: 100%;
            background: #bfd8f9;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            border: 2px solid #FFFFFF;
            border-radius: 9px;
            //display: flex;
          }
          .photo {
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-color: transparent; /* 这里设置背景颜色为透明 */
            opacity: 1.25;
            width: 100%;
            margin:0 auto;
            height: 35vh;
          }
          .title1 {
            padding: 0;
            opacity: 4;
            //text-align: left;
            font-size: 1vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: bold;
            //color: #2168DB;
            line-height: 5vh;
          }

          .title2 {
            opacity: 4;
            //text-align: center;
            justify-content: center;
            align-items: center;
            font-size:0.9vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            //color: #2168DB;
            line-height: 2.71940667490729vh;
          }
          .titleDesign{
            padding: 5vh 3vw;
            //text-align: center;
            margin:0 auto;
          }

          .consult {
            position: absolute;
            display: none;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            font-size:0.9vw;
            bottom: 3.94437577255871vh;
            left: 1.85540150202195vw;
          }
        }
      }



    }

    .screenGpts2 {
      z-index: 10;
      width: 100vw;
      height: fit-content;
      border-radius: 9px;
      padding: 4.78739184177998vh 8.09763142692085vw;
      background: transparent;

      .title {

        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 1.5vw;
        //color: #356ff8;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 4.23733003708282vh;
      }

      .light {
        margin: 0 auto 4vh auto;
        text-align: justify;
        width: 66vw;
        height: fit-content;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        //color: #3172dd;
        line-height: 4.94437577255871vh;

      }


      .pro3 {
        //margin-top: -4vh;
        height: fit-content;
        width: 100%;
        z-index: 89156;
        grid-template-columns:minmax(0, 1fr);
        row-gap: 2vh;
        .grand2 {
          //cursor: pointer;
          width: 100%;
          height:fit-content;
          padding: 0.98887515451174vh 6vw;
          opacity: 1;
          display: grid;
          text-align: center;
          grid-template-columns:  repeat(3, 1fr);
          row-gap: 5vh;
          column-gap: 3vw;

          .back {
            // transition: transform 0.5s ease;
            width: 100%;
            position: relative;
            height: 100%;
            // background: white;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            // border: 2px solid #FFFFFF;
            // border-radius: 9px;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
          .photo {
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-color: transparent; /* 这里设置背景颜色为透明 */
            opacity: 1.25;
            width: fit-content;
            height: 14vh;
          }


          .title1 {
            padding: 0;
            opacity: 4;
            text-align: left;
            font-size: 1vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: bold;
            //color: #2168DB;
            line-height: 3.6vh;
            margin-top: 3vh;
          }

          .title2 {
            opacity: 4;
            text-align: justify;
            justify-content: center;
            align-items: center;
            font-size:0.9vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            //color: #2168DB;
            line-height: 2.71940667490729vh;
          }
          .titleDesign{
            padding: 0;
            margin-left: 3vw;
            float: right;
            text-align: justify;
          }

          .consult {
            position: absolute;
            display: none;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            font-size:0.9vw;
            bottom: 3.94437577255871vh;
            left: 1.85540150202195vw;
          }
        }
        .grand {
          //cursor: pointer;
          width: 100%;
          height:fit-content;
          padding: 0.98887515451174vh 0;
          opacity: 1;
          display: grid;
          grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) ;
          row-gap: 5vh;
          column-gap: 1vw;

          .back {
            transition: transform 0.5s ease;
            width: 100%;
            position: relative;
            height: 100%;
            background: #bfd8f9;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            border: 2px solid #FFFFFF;
            border-radius: 9px;
            //display: flex;
          }
          .photo {
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-color: transparent; /* 这里设置背景颜色为透明 */
            opacity: 1.25;
            width: 100%;
            margin:0 auto;
            height: 35vh;
          }
          .title1 {
            padding: 0;
            opacity: 4;
            //text-align: left;
            font-size: 1vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: bold;
            //color: #2168DB;
            line-height: 5vh;
          }

          .title2 {
            opacity: 4;
            //text-align: center;
            justify-content: center;
            align-items: center;
            font-size:0.9vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            //color: #2168DB;
            line-height: 2.71940667490729vh;
          }
          .titleDesign{
            padding: 5vh 3vw;
            //text-align: center;
            margin:0 auto;
          }

          .consult {
            position: absolute;
            display: none;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            font-size:0.9vw;
            bottom: 3.94437577255871vh;
            left: 1.85540150202195vw;
          }
        }
      }



    }
  }

}

.totalmakeHoleModel{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .makeHole1 {
    ////z-index::: 3;
    position: relative;
    width: 100%;
    height: 100vh;
    .screen1 {
      ////z-index::: 3;
      position: relative;
      width: 100%;
      height: 400px;
      padding: 2.84301606922126vh 0  2.84301606922126vh 0;
      background-image: url("../../../assets/subordinate/special2.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */

      & > div {
        position: relative;
        z-index: 10;
      }

      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to bottom, #43a8fb 60%, rgba(198, 211, 239, 0.5));
      }
      .layer2 {
        position: absolute;
        top: 75px;
        width: 100%;

        .title1 {
          width:fit-content;
          text-align: center;
          display: flex;
          margin:0 auto;
          justify-content: center;
          align-items: center;
          font-size: 22px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: white;
        }

        .title2 {
          margin: 15px auto;
          width: 90%;
          height: fit-content;
          font-size: 14px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: white;
          line-height: 24px;
        }
        .title4{
          margin-top: 15px;

          height: 22px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          font-size:16px;
          color: #2f6dfd;
          line-height: 0px;
          font-style: normal;
          text-transform: none;
        }


      }
      .titlle3{
        position: absolute;
        bottom:30px;
        left:50%;
        transform: translateX(-60%);
        font-size: 14px;
        font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 53px;
        width: fit-content;
        margin: 0 15px;
        padding: 5px 4.6875vw;
        height: 35px;
        color:white;
        background-color: #2f6eff;
        border: 1px solid #2f6eff;
        overflow: hidden;

      }

    }
    .screenGpts1 {
      z-index: 10;
      width: 100vw;
      height: fit-content;
      //border-radius: 9px;
      padding: 25px 20px;
      background: white;

      .title {

        height: fit-content;
        opacity: 1;
        font-size: 20px;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 20px;
      }

      .light {
        margin: 15px auto 0 auto;
        text-align: justify;
        width: 90%;
        height: fit-content;
        font-size: 16px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 22px;

      }



      .pro3 {
        margin-top: 15px;
        height: fit-content;
        width: 100%;
        z-index: 89156;
        .grand2 {
          //cursor: pointer;
          width: 100%;
          height:fit-content;

          opacity: 1;
          display: grid;
          grid-template-columns: minmax(0,1fr) minmax(0,1fr);
          row-gap: 15px;
          column-gap: 5px;

          .back {
            transition: transform 0.5s ease;
            width: 100%;
            position: relative;
            height: 100%;
            background: white;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            border: 2px solid #FFFFFF;
            border-radius: 9px;
            display: flex;
          }
          .photo {
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-color: transparent; /* 这里设置背景颜色为透明 */
            opacity: 1.25;
            width: 30px;
            position: absolute;
            height: 30px;
          }


          .title1 {
            padding: 0;
            opacity: 4;
            margin-left:35px;
            text-align: left;
            font-size: 16px;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            //font-weight: bold;
            //color: #2168DB;
            line-height: 22px;
          }

          .title2 {
            opacity: 4;
            text-align: justify;
            justify-content: center;
            align-items: center;
            font-size:14px;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            //color: #2168DB;
            line-height: 2.71940667490729vh;
          }
          .titleDesign{
            padding: 0;
            margin-left: 3vw;
            float: right;
            text-align: justify;
          }

          .consult {
            position: absolute;
            display: none;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            font-size:0.9vw;
            bottom: 3.94437577255871vh;
            left: 1.85540150202195vw;
          }
        }
        .grand {
          //cursor: pointer;
          width: 100%;
          height:fit-content;
          padding: 0.98887515451174vh 0;
          opacity: 1;
          display: grid;
          grid-template-columns: minmax(0,1fr) ;
          row-gap: 10px;

          .back {
            transition: transform 0.5s ease;
            width: 100%;
            position: relative;
            height: 100%;
            background: #bfd8f9;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            border: 2px solid #FFFFFF;
            border-radius: 9px;
            //display: flex;
          }
          .photo {
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-color: transparent; /* 这里设置背景颜色为透明 */
            opacity: 1.25;
            width: 100%;
            margin:0 auto;
            height:200px;
          }


          .title1 {
            padding: 0;
            opacity: 4;
            text-align: center;
            font-size: 16px;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: bold;
            //color: #2168DB;
            line-height: 22px;
          }

          .title2 {
            opacity: 4;
            text-align: justify;
            justify-content: center;
            align-items: center;
            font-size:14px;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            //color: #2168DB;
            line-height: 20px;
          }
          .titleDesign{
            padding: 15px 15px 20px 15px;
            float: right;
            text-align: justify;
          }

          .consult {
            position: absolute;
            display: none;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            font-size:0.9vw;
            bottom: 3.94437577255871vh;
            left: 1.85540150202195vw;
          }
        }
      }



    }
    .screenGpts2 {
      z-index: 10;
      width: 100vw;
      height: fit-content;
      //border-radius: 9px;
      padding: 25px 20px;
      background: transparent;

      .title {

        height: fit-content;
        opacity: 1;
        font-size: 20px;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 20px;
      }

      .light {
        margin: 15px auto 0 auto;
        text-align: justify;
        width: 90%;
        height: fit-content;
        font-size: 16px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 22px;

      }



      .pro3 {
        margin-top: 15px;
        height: fit-content;
        width: 100%;
        z-index: 89156;
        .grand2 {
          //cursor: pointer;
          width: 100%;
          height:fit-content;

          opacity: 1;
          display: grid;
          grid-template-columns: minmax(0,1fr);
          row-gap: 15px;
          column-gap: 5px;

          .back {
            transition: transform 0.5s ease;
            width: 100%;
            position: relative;
            height: 100%;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            //border: 2px solid #FFFFFF;
            border-radius: 9px;
            display: flex;
          }
          .photo {
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-color: transparent; /* 这里设置背景颜色为透明 */
            opacity: 1.25;
            width: 30px;
            position: absolute;
            height: 30px;
          }


          .title1 {
            padding: 0;
            opacity: 4;
            margin-left:35px;
            text-align: left;
            font-size: 16px;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            //font-weight: bold;
            //color: #2168DB;
            line-height: 22px;
          }

          .title2 {
            opacity: 4;
            text-align: justify;
            justify-content: center;
            align-items: center;
            font-size:14px;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            //color: #2168DB;
            line-height: 2.71940667490729vh;
          }
          .titleDesign{
            padding: 0;
            margin-left: 3vw;
            float: right;
            text-align: justify;
          }

          .consult {
            position: absolute;
            display: none;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            font-size:0.9vw;
            bottom: 3.94437577255871vh;
            left: 1.85540150202195vw;
          }
        }
        .grand {
          //cursor: pointer;
          width: 100%;
          height:fit-content;
          padding: 0.98887515451174vh 0;
          opacity: 1;
          display: grid;
          grid-template-columns: minmax(0,1fr) ;
          row-gap: 10px;

          .back {
            transition: transform 0.5s ease;
            width: 100%;
            position: relative;
            height: 100%;
            background: #bfd8f9;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            border: 2px solid #FFFFFF;
            border-radius: 9px;
            //display: flex;
          }
          .photo {
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-color: transparent; /* 这里设置背景颜色为透明 */
            opacity: 1.25;
            width: 100%;
            margin:0 auto;
            height:200px;
          }


          .title1 {
            padding: 0;
            opacity: 4;
            text-align: center;
            font-size: 16px;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: bold;
            //color: #2168DB;
            line-height: 22px;
          }

          .title2 {
            opacity: 4;
            text-align: justify;
            justify-content: center;
            align-items: center;
            font-size:14px;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            //color: #2168DB;
            line-height: 20px;
          }
          .titleDesign{
            padding: 15px 15px 20px 15px;
            float: right;
            text-align: justify;
          }

          .consult {
            position: absolute;
            display: none;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            font-size:0.9vw;
            bottom: 3.94437577255871vh;
            left: 1.85540150202195vw;
          }
        }
      }



    }
    .screenmake0{
      ////z-index::: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 20px;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;

      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid{
        position: relative;
        height: fit-content;
        margin-top:25px;
        .title2 {
          margin: 15px auto;
          width: 90%;
          height: fit-content;
          font-size: 14px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          line-height: 24px;
        }
        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          ////z-index::: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }

    .screenmake4{
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;

      padding:20px 4.6875vw;
      background: black;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        color: white;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .arrowButtonleft {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          left:-55px;
          ////z-index::: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }
        .arrowButtonright {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          right: -55px;
          ////z-index::: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }
        .card{
          //cursor: pointer;
          width: 98%;
          margin:0 auto;
          min-height: 250px ;
          padding:0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;
          .back{
            border-radius: 9px;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            min-height: 280px ;
            overflow:hidden;
            background: #19202e;
            .imgDesign{
              width: 100%;
              height:fit-content;
              opacity: 1;
              //background-image: url("../../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 15px 20px 25px 20px;
              color: white;
              width:100%;
              float: right;
              .title1{
                margin-top:15px;
                font-size: 16px;
              }
              .title2{
                margin-top: 15px;
                line-height: 22px;
                font-size:14px;
                ::v-deep li{
                  margin-top:5px;
                }
              ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container{
          height: 400px !important;

        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__arrow {
          display: block;
          //border: 1px solid white;
          outline: -5px;
          padding: 0;
          margin: -115px -10px  ;
          //cursor: pointer;

          border-radius: 0;
          width: 25px;
          height: 48px;
          background: rgba(245, 246, 250);
          color: #131313;
          position: absolute;
          top: 50%;
          ////z-index::: 100;
          transform: translateY(-50%);
          text-align: center;
          font-size: 14px;
        }
      }
    }
  }

}

.lr-box{
  display: flex;
  align-items: center;
  margin-top: 3vh;
  h4{
    font-size: 1.2vw;
    margin-bottom: 3vh;
  }
  p,li{
    margin-bottom: .5vh;
    font-size: .9vw;
    line-height: 2;
  }
  & > div{
    flex: 1;
  }
  & > div:first-child{
    width: 50%;
    margin-right: 3vw;
  }
}
</style>
