<template>
  <div>
    <div v-if="model===false">
      <div class="totalmakeHole">
        <div class="makeHole1">
          <div class="screen1">
            <headtip @showDialog="showPartner"></headtip>
            <div class="layer2">
              <div class="title1">特种聚合物干粉变粘压裂液
              </div>
              <div class="title2">特种聚合物干粉压裂液是是一种直接利用干粉减阻剂配置的无油相、速溶、
                高抗盐、低伤害、在线变粘的压裂液体系。通过常规聚合物干粉改性，使其溶解性能和抗盐性能极大提高，同时研制出配套的高效连续混配设备，实现压裂液的即供、即配、即注，达到提高施工效率、降低综合成本的效果。
              </div>
              <div class="titlle3" @click="clickConsult('地质工程云平台')">
                产品咨询
              </div>
            </div>
            <!--      平台大图-->
            <div class="layer3"></div>
          </div>

          <div ref="consultMove" class="screenGpts text-box" style="background-color: #fff;">
            <div style="display: flex;margin:0 auto;">
              <div style="display: flex;margin:0 auto 0 auto;text-align: center;">
                <!--              <div style="height:2.2vw;text-align: right"><img loading="lazy" src="@/assets/icon_first.png">-->
                <!--              </div>-->
                <div class="title">&nbsp;特种聚合物干粉变粘压裂液
                </div>
              </div>
            </div>
            <div class="pro3">

              <!--油田-->
              <div class="grand2">
                <div class="back ">

                  <div class="titleDesign">
                    <div class="title1">技术原理</div>
                    <div class="title2">
                      <li>
                        专用粉剂减阻剂：通过特殊聚合物分子设计和颗粒表面纳米处理特殊生产工艺形成干粉聚合物减阻剂，具有易分散且水化时间快的特点，能够快速实现粘度要求。
                      </li>
                      <li>
                        可控流变智能在线配液设备：通过在线连续混配装置高速混合，无需油悬浮技术即可实现速溶；先形成浓缩液体后泵送至混砂车稀释，排量不受影响。
                      </li>
                    </div>
                  </div>
                </div>
              </div>

              <div class="grand2">
                <div class="back ">

                  <div class="titleDesign">
                    <div class="title1">技术特点</div>
                    <div class="title2">
                      <li>速溶、有效含量高、高耐盐、低伤害、连续混配实时可调、QHSE风险低
                      </li>
                    </div>
                  </div>
                </div>
              </div>

              <div class="grand2">
                <div class="back ">

                  <div class="titleDesign">
                    <div class="title1">应用方向</div>
                    <div class="title2">
                      <li>适用页岩油气、致密油气、煤层气等非常规储层大规模压裂作业中，对乳液体系的替代，实现降本增效。</li>
                    </div>
                  </div>
                </div>
              </div>


              <div class="grand2">
                <div class="back ">

                  <div class="titleDesign">
                    <div class="title1">服务业绩</div>
                    <div class="title2">
                      <li>
                        2023~2024年应用30余井次400余井段，涵盖页岩、砂岩、煤岩、碳酸盐岩储层，粉剂直配变粘滑溜水超过60万方，满足了在线混配、大排量、大砂量要求，施工成功率100%；降本增效明显，平均单井降本10%以上，产量超过邻井20%以上。
                      </li>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div class="screenmake4">
            <div class="heighLight">成功案例</div>
            <div class="grid">
              <el-carousel arrow="always" indicator-position="outside">
                <el-carousel-item v-for="item in success" :key="item">
                  <div class="card">
                    <div class="back">

                      <div style="background-color: white;width: 55%">
                        <div class="imgDesign">
                          <img :src="item.img" loading="lazy"/>
                        </div>
                      </div>

                      <div class="titleDesign">
                        <div class="title1" v-html="item.title2"></div>
                        <div class="title1" v-html="item.title1"></div>
                        <div class="title2" v-html="item.title3"></div>
                      </div>
                    </div>


                  </div>


                </el-carousel-item>
              </el-carousel>
            </div>

          </div>
          <bottomTip></bottomTip>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
    <div v-else>
      <div class="totalmakeHoleModel">
        <div class="makeHole1">
          <div class="screen1">
            <div style="margin:0 15px;">
              <headtip @showDialog="showPartner"></headtip>
            </div>
            <div class="layer2">
              <div class="title1">特种聚合物干粉变粘压裂液
              </div>
              <div class="title2">特种聚合物干粉压裂液是是一种直接利用干粉减阻剂配置的无油相、速溶、
                高抗盐、低伤害、在线变粘的压裂液体系。通过常规聚合物干粉改性，使其溶解性能和抗盐性能极大提高，同时研制出配套的高效连续混配设备，实现压裂液的即供、即配、即注，达到提高施工效率、降低综合成本的效果。
              </div>
            </div>
            <div class="titlle3" @click="clickConsult('地质工程云平台')">
              产品咨询
            </div>
            <!--      平台大图-->
            <div class="layer3"></div>
          </div>
          <div ref="consultMove" class="screenGpts " style="background-color: #fff;">
            <div style="display: flex;margin:0 auto;">
              <div style="display: flex;margin:0 auto 0 auto;text-align: center;">
                <!--              <div style="height:2.2vw;text-align: right"><img loading="lazy" src="@/assets/icon_first.png">-->
                <!--              </div>-->
                <div class="title">&nbsp;特种聚合物干粉变粘压裂液
                </div>
              </div>
            </div>
            <div class="pro3">

              <!--油田-->
              <div class="grand2">
                <div class="back ">

                  <div class="titleDesign">
                    <div class="title1">技术原理</div>
                    <div class="title2">
                      <li>
                        专用粉剂减阻剂：通过特殊聚合物分子设计和颗粒表面纳米处理特殊生产工艺形成干粉聚合物减阻剂，具有易分散且水化时间快的特点，能够快速实现粘度要求。
                      </li>
                      <li>
                        可控流变智能在线配液设备：通过在线连续混配装置高速混合，无需油悬浮技术即可实现速溶；先形成浓缩液体后泵送至混砂车稀释，排量不受影响。
                      </li>
                    </div>
                  </div>
                </div>
              </div>

              <div class="grand2">
                <div class="back ">

                  <div class="titleDesign">
                    <div class="title1">技术特点</div>
                    <div class="title2">
                      <li>速溶、有效含量高、高耐盐、低伤害、连续混配实时可调、QHSE风险低
                      </li>
                    </div>
                  </div>
                </div>
              </div>

              <div class="grand2">
                <div class="back ">

                  <div class="titleDesign">
                    <div class="title1">应用方向</div>
                    <div class="title2">
                      <li>适用页岩油气、致密油气、煤层气等非常规储层大规模压裂作业中，对乳液体系的替代，实现降本增效。</li>
                    </div>
                  </div>
                </div>
              </div>


              <div class="grand2">
                <div class="back ">

                  <div class="titleDesign">
                    <div class="title1">服务业绩</div>
                    <div class="title2">
                      <li>
                        2023~2024年应用30余井次400余井段，涵盖页岩、砂岩、煤岩、碳酸盐岩储层，粉剂直配变粘滑溜水超过60万方，满足了在线混配、大排量、大砂量要求，施工成功率100%；降本增效明显，平均单井降本10%以上，产量超过邻井20%以上。
                      </li>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div class="screenmake4">
            <div class="heighLight">成功案例</div>
            <div class="grid">
              <el-carousel arrow="always" indicator-position="outside">
                <el-carousel-item v-for="item in success" :key="item">
                  <div class="card">
                    <div class="back">

                      <div style="background-color: white;">
                        <div class="imgDesign">
                          <img :src="item.img" loading="lazy"/>
                        </div>
                      </div>

                      <div class="titleDesign">
                        <div class="title1" style="line-height: 16px" v-html="item.title2"></div>
                        <div class="title2" v-html="item.title3"></div>
                      </div>
                    </div>


                  </div>


                </el-carousel-item>
              </el-carousel>
            </div>

          </div>
          <div ref="bottom">
            <bottomTip></bottomTip>
          </div>

        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
  </div>


</template>


<script>

import headtip from "@/views/headTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import consultTip from "@/components/consultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import {shareUrl} from "@/utils/vxshare";

export default {

  name: "",

  props: [],

  components: {PartnerTip, consultTip, headtip, bottomTip},

  data() {
    return {
      title: '',
      pageTitle: '特种聚合物干粉变粘压裂液-油气增产技术GPTs-地质工程云平台GPT',
      pageDescription: '特种聚合物干粉压裂液是是一种直接利用干粉减阻剂配置的无油相、速溶、 高抗盐、低伤害、在线变粘的压裂液体系。通过常规聚合物干粉改性，使其溶解性能和抗盐性能极大提高，同时研制出配套的高效连续混配设备，实现压裂液的即供、即配、即注，达到提高施工效率、降低综合成本的效果。',
      pageKeywords: '特种聚合物干粉变粘压裂液,油气增产技术GPTs,地质工程云平台GPT',
      model: false,
      dialogconsult: false,
      dialogVisible: false,
      success: [{
        img: require("@/assets/subordinate/specialpolymer1.png"),
        title2: '江汉涪陵页岩气应用案例<br><br>',
        title3: "" +
            "<li>焦页XX井全井采取清污混配配液，高效完成29段压裂施工，液体粘度稳定，配液设备运转正常，各项施工参数均与设计相符，实现了高排量安全施工及控液保砂目标。现场试验结果表明，与原乳剂压裂液相比，在清污混配条件下，相同使用浓度的表观粘度可提升2倍，实测液体降阻率75.5~80.6%，具备高降阻特点。</li>"
      }, {
        img: require("@/assets/subordinate/specialpolymer2.png"),
        title2: '中石化华北局致密砂岩气应用案例<br><br>',
        title3: "" +
            "<li>XFXX井为低压致密砂岩气层，采用特种干粉压裂液完成23段压裂施工，6~7m<sup>3</sup>/min排量实现了25%~30%砂比连续加砂，加砂符合率100%，压后产量创区块记录。</li>"
      },
      ]
    }

  },


  methods: {
    clickConsult(item) {

      this.title = item
      this.dialogVisible = true;
      sessionStorage.setItem('title', item)
    },
    hide() {
      this.dialogconsult = false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    realtime() {
      window.open("/realtime")
    },
    showPartner() {
      this.dialogconsult = true;
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    }, setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
  },

  mounted() {
    document.title = this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);
    if (screenWidth <= 700) {
      this.model = true
    } else {
      this.model = false
    }

    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
  },
  created() {
    localStorage.setItem("home", 0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: this.pageTitle, //'智能工程迭代优化GPTs-工程地质技术GPTs-地质工程云平台GPT',
        pageDescription: this.pageDescription, //'依托云的管理模式，工程服务与地质相结合，立足油气田已有成果，结合基础油藏地质研究，提供实时、动态的优化建议，以达到提升工程效果，降低工程风险的效果。',
        pageKeywords: this.pageKeywords, //'智能工程迭代优化GPTs,工程地质技术GPTs,地质工程云平台GPT',

      };
      shareUrl(shareData);
    }, 1000);
  },
}

</script>


<style lang="scss" scoped type="text/scss">
img {
  object-fit: contain;
}

.totalmakeHole {
  background: #c0d8f9;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;

  .makeHole1 {
    ////z-index::: 3;
    position: relative;
    width: 100%;
    height: 100vh;

    .screen1 {
      ////z-index::: 3;
      position: relative;
      width: 100%;
      height: 74vh;

      padding: 2.84301606922126vh 1.5vw 2.84301606922126vh 5vw;
      background-image: url("../../../assets/subordinate/connect1.png");
      background-position: right;
      background-size: contain;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */

      & > div {
        position: relative;
        z-index: 10;
      }

      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, #becff5 30%, rgba(198, 211, 239, 0.5));
      }

      .layer2 {
        position: absolute;
        top: 50%;
        transform: translateY(-43%);
        left: 6.5vw;

        .title1 {
          width: fit-content;
          height: fit-content;
          font-size: 2.5vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #2f6eff;
          line-height: 3vh;
        }

        .title2 {
          margin-top: 5vh;
          width: 40vw;
          height: fit-content;
          text-align: justify;
          font-size: 1.0597920277vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: black;
          line-height: 4.31520395550062vh;
        }

        .titlle3 {
          margin-top: 5vh;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #ffffff;
          line-height: 3.3374536465vh;
          text-align: center;
          border-radius: 53px;
          width: fit-content;
          padding: 2.4624227441vh 1.1989601386vw;
          height: 4.5624227441vh;
          background: #2f6eff;
          border: #2f6eff 1px solid;

        }

        .titlle3:hover {

          color: #2168DB;
          border: #2168DB 1px solid;
          background: rgba(255, 255, 255, 0.5);
        }
      }


      //.layer3 {
      //  position: absolute;
      //  top: 5.06798516687268vh;
      //  right: 11.61178509532062vw;
      //  width: 47.42923165800116vw;
      //  height: 72.80593325092707vh;
      //  background-image: url("../assets/GPTplant.png");
      //  background-size: 100% auto;
      //  background-position: center;
      //  background-repeat: no-repeat;
      //  background-color: transparent;
      //  opacity: 1;
      //}
    }

    .screenGpts {
      z-index: 10;
      width: 100vw;
      height: fit-content;
      border-radius: 9px;
      padding: 4.78739184177998vh 8.09763142692085vw;
      background: transparent;

      .title {

        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 1.5vw;
        //color: #356ff8;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 4.23733003708282vh;
      }

      .light {
        margin: 0 auto 4vh auto;
        text-align: justify;
        width: 66vw;
        height: fit-content;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        //color: #3172dd;
        line-height: 4.94437577255871vh;

      }


      .pro3 {
        //margin-top: -4vh;
        height: fit-content;
        width: 100%;
        z-index: 89156;

        grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        row-gap: 2vh;

        .grand2 {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.39324090121317vw;
          opacity: 1;

          .back {
            transition: transform 0.5s ease;
            width: 100%;
            position: relative;
            height: 100%;
            background: white;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            border: 2px solid #FFFFFF;
            border-radius: 9px;
            display: flex;
          }

          .photo {
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-color: transparent; /* 这里设置背景颜色为透明 */
            opacity: 1.25;
            margin: 0 auto auto auto;
            display: flex;
            justify-content: center;
            text-align: center;
            width: fit-content;
            height: 100%;
          }


          .title1 {
            padding: 0;
            opacity: 4;
            text-align: left;
            font-size: 1vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: bold;
            //color: #2168DB;
            line-height: 5vh;
          }

          .title2 {
            opacity: 4;
            text-align: justify;
            justify-content: center;
            align-items: center;
            font-size: 0.9vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            //color: #2168DB;
            line-height: 2.71940667490729vh;
          }

          .titleDesign {
            margin: auto;
            padding: 0 3vw;
            width: 50%;
            float: right;
            text-align: justify;
          }

          .consult {
            position: absolute;
            display: none;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            font-size: 0.9vw;
            bottom: 3.94437577255871vh;
            left: 1.85540150202195vw;
          }
        }

        .grand2:hover {
          .back {
            transform: translateY(-10px);

          }

        }
      }

      &.text-box {
        .pro3 {
          display: grid;
          grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
          row-gap: 2vh;

          .grand2 {
            height: 100%;
            .back {
              min-height: fit-content;

              .titleDesign {
                margin: unset;
                width: unset;
                padding: 2vh 3vh 3vh;

                .title1 {
                  line-height: 1.4;
                  margin-bottom: 2vh;
                  text-align: center;
                }
              }
            }
          }
        }
      }


    }

    .screenmake4 {
      padding: 5vh 3.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: #212a3c;

      .heighLight {
        display: flex;
        justify-content: center;
        text-align: center;
        height: 5.23733003708282vh;
        font-size: 1.5vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: bold;
        color: white;
        line-height: 4.23733003708282vh;

      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;

        .card {
          //cursor: pointer;
          width: 86%;
          margin: 0 auto;
          height: fit-content;
          padding: 0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;

          .back {
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height: 45vh;
            overflow: hidden;
            background: #19202e;

            .imgDesign {
              background-color: white;
              width: fit-content;
              height: 100%;
              margin: 0 auto;
              opacity: 1;
            }

            .titleDesign {
              margin: auto;
              color: white;
              width: 35%;
              float: right;
              text-align: left;

              .title1 {
                line-height: 4vh;
                font-size: 1.1709416522vw;
              }

              .title2 {
                line-height: 3.5vh;
                font-size: 0.9vw;

                ::v-deep li {
                  margin-top: 5px;
                }
              ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 50vh !important;

        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          outline: 10vh;
          padding: 0;
          margin: 0;
          //cursor: pointer;

          border-radius: 50%;
          width: 55px;
          height: 55px;
          background-color: rgba(249, 250, 252, 0.2);
          border: rgba(255, 255, 255, 0.5) 1px solid;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 100;
          transform: translateY(-50%);
          text-align: center;
          font-weight: 800;
          font-size: 25px;
        }
      }
    }

    .screenmake0 {
      padding: 5vh 15.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height: 6.23733003708282vh;
        opacity: 1;
        font-weight: bold;
        font-size: 1.5vw;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin: 0 auto 20px;
        // text-align: center;
        height: 8.78739184177998vh;
        // font-weight: bold;
        color: #2C3E50;
        line-height: 4.94437577255871vh;
        width: 60.9693818602vw;
        font-size: 1vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            background: #EFF5FF;
            border-radius: 9px;
            display: flex;
            position: relative;
            box-shadow: 2px 0 12px 0px #c9d9f5;
            height: 66.87144622991347vh;
            overflow: hidden;

            .imgDesign {
              width: fit-content;
              height: 100%;
              opacity: 1;
              background-image: url("../../../assets/menage6.png");
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 4.5248454883vh 2.5248454883vw 2.5248454883vh 1.5248454883vw;
              margin: 0 1vw auto auto;
              color: black;
              width: 40%;
              float: right;

              .title1 {
                margin-top: 2vh;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }

              .title2 {
                margin-top: 1vh;
                text-align: justify;
                line-height: 3.194066749vh;
                font-size: 0.9vw;;
              }

              .title3 {
                display: grid;
                grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.9vw;;
              }

            }

          }
        }


      }
    }
  }

}

.totalmakeHoleModel{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .makeHole1 {
    ////z-index::: 3;
    position: relative;
    width: 100%;
    height: 100vh;
    .screen1 {
      ////z-index::: 3;
      position: relative;
      width: 100%;
      height: 400px;
      padding: 2.84301606922126vh 0  2.84301606922126vh 0;
      background-image: url("../../../assets/groundHome7M.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */

      .layer2 {
        position: absolute;
        top: 75px;
        width: 100%;

        .title1 {
          width:fit-content;
          text-align: center;
          display: flex;
          margin:0 auto;
          justify-content: center;
          align-items: center;
          font-size: 22px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: white;
        }

        .title2 {
          margin: 15px auto;
          width: 90%;
          height: fit-content;
          font-size: 14px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: white;
          line-height: 24px;
        }
        .title4{
          margin-top: 15px;

          height: 22px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          font-size:16px;
          color: #2f6dfd;
          line-height: 0px;
          font-style: normal;
          text-transform: none;
        }
      }
      .titlle3{
        position: absolute;
        bottom:30px;
        left:50%;
        transform: translateX(-60%);
        font-size: 14px;
        font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 53px;
        width: fit-content;
        margin: 0 15px;
        padding: 5px 4.6875vw;
        height: 35px;
        color:white;
        background-color: #2f6eff;
        border: 1px solid #2f6eff;
        overflow: hidden;

      }

    }
    .screenGpts {
      width: 100vw;
      height: fit-content;

      padding:20px 4.6875vw;
      background: black;
      ::v-deep .li{
        list-style-position:outside !important;
      }
      .title{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        //color: white;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }
      .light{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .pro3 {
        margin-top: 25px;
        height: fit-content;
        width: 100%;
        z-index: 89156;

        grid-template-columns:minmax(0, 1fr);
        row-gap: 15px;

        .grand2{
          //cursor: pointer;
          width: 98%;
          margin:0 auto;
          padding:0 0 0 0;
          opacity: 1;
          .back{
            border-radius: 9px;
            position: relative;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            overflow:hidden;
            margin-top: 15px;
            .imgDesign{
              width: 100%;
              height:200px;
              opacity: 1;
              //background-image: url("../../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 15px 20px 25px 20px;
              width:100%;

              .title1{
                text-align: center;
                color: #316fff;
                font-size: 16px;
              }
              .title2{
                margin-top: 15px;
                line-height: 22px;
                font-size:14px;
                ::v-deep li{
                  margin-top:5px;
                  list-style-position:outside;
                }
              ;
              }


            }

          }
        }


        .grand2:hover {
          .back {
            transform: translateY(-10px);

          }

        }
      }



    }

    .screenmake4{
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;

      padding:20px 4.6875vw;
      background: black;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        color: white;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .arrowButtonleft {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          left:-55px;
          ////z-index::: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }
        .arrowButtonright {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          right: -55px;
          ////z-index::: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }
        .card{
          //cursor: pointer;
          width: 98%;
          margin:0 auto;
          min-height: 250px ;
          padding:0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;
          .back{
            border-radius: 9px;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            min-height: 280px ;
            overflow:hidden;
            background: #19202e;
            .imgDesign{
              width: 100%;
              height:200px;
              opacity: 1;
              //background-image: url("../../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 15px 20px 25px 20px;
              color: white;
              width:100%;
              float: right;
              .title1{
                margin-top:15px;
                font-size: 16px;
              }
              .title2{
                line-height: 22px;
                font-size:14px;
                ::v-deep li{
                  margin-top:5px;
                }
              ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container{
          height: 440px !important;

        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__arrow {
          display: block;
          //border: 1px solid white;
          outline: -5px;
          padding: 0;
          margin: -118px -10px  ;
          //cursor: pointer;

          border-radius: 0;
          width: 25px;
          height: 48px;
          background: rgba(245, 246, 250);
          color: #131313;
          position: absolute;
          top: 50%;
          ////z-index::: 100;
          transform: translateY(-50%);
          text-align: center;
          font-size: 14px;
        }
      }
    }
  }

}
</style>
